<template>
  <!-- 采购明细 -->
  <div class="module-wrapper m-purchase-order-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-purchase-order-line"
      :columns="columns"
      :item-source="itemSource"
      :totalField="['Amount', 'Qty']"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('ordPurchaseOrderLine')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      :showCheck="true"
      checkbox-binding-key="POLineGuid"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span class="znl-header-text">· 采购明细</span>
        <znl-button style-type="mac" type="multiple">
          <znl-button-menu @click="onPrint">
            <span> <i class="iconfont icon-print_btn_ic"></i>打印 </span>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="mac" :height="22" @click="onPurchaseReturn()">
          <i class="iconfont icon-backout_btn_ic"></i>
          <span>采购退货</span>
        </znl-button>

        <znl-button
          v-if="isMainAccount || hasRes('Add') || hasRes('Edit')"
          style-type="mac"
          :height="22"
          @click="onCancelQty()"
        >
          <i class="iconfont icon-backout_btn_ic"></i><span>取消未入数量</span>
        </znl-button>
      </div>
    </znl-table>
    <!-- <print-template ref="PrintTemplate">
    </print-template>-->

    <!-- 退货 -->
    <ord-purchase-return-edit
      :editvisible="ordReturnEditVisible"
      v-if="ordReturnEditVisible"
      :edit-type="editType"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
      @confirm="ordReturnEditVisible = false"
      @close="ordReturnEditVisible = false"
    ></ord-purchase-return-edit>

    <!-- 取消未入数量 -->
    <CancelOrderLineQty
      v-if="cancelOrderLineQtyVisible"
      :visible="cancelOrderLineQtyVisible"
      :purchaseOrderLineGuids="purchaseOrderLineGuids"
      @close="(val) => onCancelQtyClose(val)"
    ></CancelOrderLineQty>
  </div>
</template>

<script>
// import PrintTemplate from '@c_modules/PPM/PrintTemplate'
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import * as urls from "~/lib/urls";
import OrdPurchaseReturnEdit from "@c_modules/Purchase/OrderReturnEdit";
import CancelOrderLineQty from "./CancelOrderLineQty.vue";

const CONFIG = {
  PK: "POLineGuid",
  configURL: "OrdPurchaseOrderLine/GetConfig",
  searchURL: "OrdPurchaseOrderLine/Search",
  pdfURL: "OrdPurchaseOrderLine/DownloadTrackCodePdf",
  apiBaseUrl: urls.getErpApiBaseUrl(),
};

export default {
  name: "PurchaseOrderLine",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    // PrintTemplate
    OrdPurchaseReturnEdit,
    CancelOrderLineQty,
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      parameterConfig: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      isShowPrint: false,
      ordReturnEditVisible: false,
      cancelOrderLineQtyVisible: false,
      purchaseOrderLineGuids: [],
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage(
          "ordPurchaseOrderLine",
          CONFIG.configURL
        );
      }
      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }
      let columns = config.ColumnConfigs;

      this.columns = columns;
      this.$refs.table.init();
      this.parameterConfig = config.ParamJosn;
      this.$emit("page-loading", false);
      this.isShowPrint = this.isMainAccount || this.hasRes("Print");
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      if (!this.hasValue(this.POGuid)) {
        return;
      }
      let data = _.extend(
        {},
        {
          POGuid: this.POGuid,
        }
      );
      return await this.$post(CONFIG.searchURL, data, (data) => {
        this.itemSource = data === undefined ? [] : data;
      });
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("ordPurchaseOrderLine");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    onResetSetting() {
      localStorage.removeItem("ordPurchaseOrderLine");
      return this.$post(CONFIG.resetConfigURL);
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("ordPurchaseOrderLine");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          [column],
          this.pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    paramConfiguration() {
      // 默认税率
      let paramUnit = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyUnit") {
          return paramjosn.ParamValue;
        }
      });
      this.QtyUnit = paramUnit.ParamValue;
      // 默认库存类型
      let paramQtyType = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyType") {
          return paramjosn.ParamValue;
        }
      });
      this.QtyType = paramQtyType.ParamValue;
    },
    onHeadSearch(poGuid, supplierName) {
      if (poGuid) {
        this.POGuid = poGuid;
        this.onBindData();
      }
      if (supplierName) {
        this.SupplierName = supplierName;
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("OrdPurchaseOrderLine", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("OrdPurchaseOrderLine", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("OrdPurchaseOrderLine", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("OrdPurchaseOrderLine");
    },
    onPrint() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows.length == 0) {
        return this.$message({
          message: "请选中要打印的采购明细",
          type: "error",
        });
      }
      var poLineID = "";
      var trackCodeIsNull = false;
      _.each(rows, (data) => {
        poLineID += data.POLineID + ",";
        if (data.TrackCode == undefined || data.TrackCode == "") {
          trackCodeIsNull = true;
        }
      });
      if (trackCodeIsNull) {
        this.$message({ message: "打印的数据跟踪码不能为空", type: "error" });
        return;
      }
      this.$post(
        CONFIG.pdfURL,
        { POGuid: this.POGuid, POLineId: poLineID },
        (data, logicmsg) => {
          if (data != undefined && data != "") {
            this.downFile(
              {
                FileName: "采购明细跟踪码.pdf",
                FileUrl: `${CONFIG.apiBaseUrl}` + data,
              },
              function (res) {}
            );
          } else if (logicmsg != undefined && logicmsg.msg != undefined) {
            this.$message({ message: logicmsg.msg, type: "error" });
          }
        }
      );
      // let fileUrl = `${CONFIG.apiBaseUrl}OrdPurchaseOrderLine/DownloadTrackCodePdf?companyId=` + this.$store.state.accountInfo.CompanyID + "&poGuid=" + this.POGuid + "&poLineID=" + poLineID
      // this.downFile({
      //   FileName: '采购明细跟踪码.pdf',
      //   FileUrl: fileUrl,
      // }, function (res) { })

      // this.$refs.PrintTemplate.initPrintDetails(rows, this.SupplierName, true)
    },
    // 采购退货
    onPurchaseReturn() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows === null || rows === undefined || rows.length === 0) {
        this.$message({
          message: "请至少勾选一条数据进行操作",
          type: "error",
        });
        return false;
      }
      // let msg = "";
      // rows.some((row) => {
      //   if (row.ReturnQty >= row.Qty) {
      //     msg = `型号${row.Model}已经全部退货，不能重复退货`;
      //     return true;
      //   }
      //   if (row.Status !== 3) {
      //     msg = `型号${row.Model}不是已入库状态，不能退货`;
      //     return true;
      //   }
      // });
      // if (msg !== "") {
      //   this.$message({ message: msg, type: "error" });
      //   return false;
      // }
      let guids = rows.map((row) => row.POLineGuid);
      let paramOrder = { purchaseOrderLines: guids };
      this.$store.commit("setParamOrder", paramOrder);
      this.$nextTick(function () {
        this.$refs.table.cleanAllCheck();
        this.editType = "edit";
        this.ordReturnEditVisible = true;
      });
    },
    // 取消未入数量
    async onCancelQty() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows === undefined || rows === null || rows.length === 0) {
        return this.$message({
          message: "请勾选要取消的采购明细",
          type: "error",
        });
      }
      this.purchaseOrderLineGuids = _.map(rows, (item) => {
        return item.POLineGuid;
      });
      this.cancelOrderLineQtyVisible = true;
    },
    // 关闭弹窗
    onCancelQtyClose(isChanged) {
      this.cancelOrderLineQtyVisible = false;
      if (isChanged) {
        this.onBindData();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
