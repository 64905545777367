<template>
  <!-- 发快递 -->
  <div class="kdcontainer">
    <el-tabs class="tab-btnstyle-box"
      v-loading="Loading"
      element-loading-text="订单发送中..">
      <el-tab-pane label="订单详情">

        <el-row :gutter="20">
          <div class="grid-content">
            <el-row class="title_msg">
              <el-col>
                <el-row>
                  1. 请填写寄件人,收件人和物品信息(必填项)
                </el-row>
              </el-col>
              <el-col class="mt20">
                <el-row>
                  2. 提交订单后.快递公司将会发送短信到寄件人手机上,通知预计取件时间
                </el-row>
              </el-col>
              <el-col class="mt20">
                <el-row>
                  3. 揽件员上门取件收费
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-row>

        <!-- v-if="!isAutoselectexpNext" -->
        <el-row :gutter="20"
          class=""
          v-if="true">
          <el-col :span="14">
            <div class="grid-content bg-purple">
              <!--寄快递 start-->
              <div class="b d-title">寄件人信息</div>
              <div class="znl-row">
                <znl-input :border="true"
                  width="44%"
                  v-model="RD.Sender.Name"
                  ref="inputSenderName"
                  placeholder="请输入真实寄件人姓名"
                  tabindex="1"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputSenderName.blur()">
                </znl-input>
                <znl-input :border="true"
                  width="44%"
                  v-model="RD.Sender.Mobile"
                  ref="inputSenderMobile"
                  placeholder="请输入手机号"
                  tabindex="2"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputSenderMobile.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  width="29.2%"
                  form-type="select"
                  :select-options="provinceOptions"
                  @change="senderChoseProvince"
                  ref="inputSenderProvinceName"
                  v-model="rdSenderPCE.ProvinceCode"
                  placeholder="请选择省份"
                  tabindex="3"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputSenderProvinceName.blur()">
                </znl-input>
                <znl-input :border="true"
                  width="29%"
                  form-type="select"
                  :select-options="senderCityOptions"
                  @change="senderChoseCity"
                  ref="inputSenderCityName"
                  v-model="rdSenderPCE.CityCode"
                  placeholder="请选择城市"
                  tabindex="3"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputSenderCityName.blur()">
                </znl-input>
                <znl-input :border="true"
                  width="29%"
                  form-type="select"
                  :select-options="senderExpAreaOptions"
                  @change="senderchoseBlock"
                  ref="inputSenderExpAreaName"
                  v-model="rdSenderPCE.ExpAreCode"
                  placeholder="请选择地区"
                  tabindex="3"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputSenderExpAreaName.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  width="89%"
                  ref="inputSenderAddress"
                  v-model="RD.Sender.Address"
                  placeholder="请输入详细地址"
                  :rows="2"
                  tabindex="4"
                  type="textarea"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputSenderAddress.blur()">
                </znl-input>
              </div>

              <!--寄快递 end-->
            </div>
            <div class="grid-content bg-purple">
              <div class="b d-title">收件人信息</div>
              <div class="znl-row">
                <znl-input :border="true"
                  width="44%"
                  ref="inputReceiverName"
                  v-model="RD.Receiver.Name"
                  placeholder="请输入收件人真实姓名"
                  tabindex="5"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputTest.blur()">
                </znl-input>
                <znl-input :border="true"
                  width="44%"
                  ref="inputReceiverMobile"
                  v-model="RD.Receiver.Mobile"
                  placeholder="请输入手机号"
                  tabindex="6"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputTest.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  width="29.2%"
                  ref="inputReceiverProvinceName"
                  form-type="select"
                  :select-options="provinceOptions"
                  @change="receiverChoseProvince"
                  v-model="rdReceiverPCE.ProvinceCode"
                  placeholder="请选择省市区"
                  tabindex="7"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputReceiverProvinceName.blur()">
                </znl-input>
                <znl-input :border="true"
                  width="29%"
                  ref="inputReceiverCityName"
                  form-type="select"
                  :select-options="receiverCityOptions"
                  @change="receiverChoseCity"
                  v-model="rdReceiverPCE.CityCode"
                  placeholder="请选择省市区"
                  tabindex="7"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputReceiverCityName.blur()">
                </znl-input>
                <znl-input :border="true"
                  width="29%"
                  ref="inputReceiverExpAreaName"
                  form-type="select"
                  :select-options="receiverExpAreaOptions"
                  @change="receiverChoseBlock"
                  v-model="rdReceiverPCE.ExpAreCode"
                  placeholder="请选择省市区"
                  tabindex="7"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputReceiverExpAreaName.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  width="89%"
                  ref="inputReceiverAddress"
                  v-model="RD.Receiver.Address"
                  placeholder="请输入详细地址"
                  tabindex="8"
                  :rows="2"
                  type="textarea"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputReceiverAddress.blur()">
                </znl-input>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple rside">
              <div class="b d-title">物品信息</div>
              <div class="znl-row">
                <znl-input :border="true"
                  ref="inputShipperCode"
                  v-model="RD.ShipperCode"
                  title="物流公司："
                  title-width="70px"
                  form-type="select"
                  :select-options="shipperOptions"
                  placeholder=""
                  tabindex="9"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputShipperCode.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  ref="inputLogisticCode"
                  v-model="RD.LogisticCode"
                  title="快递单号："
                  title-width="70px"
                  placeholder=""
                  tabindex="9"
                  @keydown.enter.native="$refs.inputLogisticCode.blur()">
                </znl-input>
              </div>

              <div class="znl-row">
                <znl-input :border="true"
                  ref="inputCommodityGoodsquantity"
                  v-model="commodity.Goodsquantity"
                  title="数  量："
                  title-width="70px"
                  placeholder=""
                  tabindex="11"
                  @keydown.enter.native="$refs.inputCommodityGoodsquantity.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  ref="inputCommodityGoodsVol"
                  v-model="commodity.GoodsVol"
                  title="体  积："
                  title-width="70px"
                  placeholder=""
                  tabindex="12"
                  @keydown.enter.native="$refs.inputCommodityGoodsVol.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  ref="inputCommodityGoodsWeight"
                  v-model="commodity.GoodsWeight"
                  title="重  量："
                  title-width="70px"
                  placeholder=""
                  tabindex="13"
                  @keydown.enter.native="$refs.inputCommodityGoodsWeight.blur()">
                </znl-input>
              </div>
              <div class="znl-row">
                <znl-input :border="true"
                  ref="inputCommodityGoodsName"
                  v-model="commodity.GoodsName"
                  title="物品信息："
                  title-width="70px"
                  placeholder=""
                  tabindex="10"
                  :rows="2"
                  type="textarea"
                  :is-must-fill='true'
                  @keydown.enter.native="$refs.inputCommodityGoodsName.blur()">
                </znl-input>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="10"
          class=""
          v-if="!isAutoselectexpNext">
          <div class="grid-content bg-purple">
            <div class="znl-row"
              style="text-align: center;">
              <znl-button @click="onNextStep"
                :disabled="isNextStepDisable"
                :height="30"
                :width="150"
                style-type="main">
                <span>提交订单</span>
              </znl-button>
              <!-- <znl-button @click="onTTCallback"
                :disabled="isNextStepDisable"
                :height="30"
                :width="150"
                style-type="main">
                <span>call back</span>
              </znl-button> -->
            </div>
          </div>
        </el-row>

      </el-tab-pane>

      <el-tab-pane label="物流轨迹"
        class="orderRecord">
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <znl-input :border="true"
                width="25%"
                v-model="kdRecord.ShipperCode"
                title-width="80px"
                title="快递公司："
                form-type="select"
                :select-options="shipperOptions"
                :is-must-fill='true'
                placeholder="">
              </znl-input>
              <znl-input :border="true"
                width="25%"
                title-width="70px"
                v-model="kdRecord.LogisticCode"
                title="物流单号："
                :is-must-fill='true'
                placeholder="">
              </znl-input>
              <znl-input :border="true"
                width="25%"
                v-model="kdRecord.OrderCode"
                title="订单号："
                placeholder="">
              </znl-input>

              <!-- <znl-input :border="true"
                width="23%"
                v-model="input1"
                title-width="80px"
                title="物流状态："
                form-type="select"
                :select-options="selectOptions"
                placeholder="请选择物流状态">
              </znl-input> -->
              <znl-button @click="onKdSearch"
                :width="80"
                style-type="main">
                查询
              </znl-button>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <znl-input :border="true"
                  :border="true"
                  width="30%"
                  v-model="input2"
                  date-type="daterange"
                  form-type="datepicker"
                  title-width="80px"
                  title="下单时间："
                  tabindex="4"
                  :clearable="true"
                  placeholder="请选择下单开始时间和结束时间">
                </znl-input>
                <znl-button :width="80" style-type="main">
                  查询
                </znl-button>
            </div>
          </el-col>
        </el-row> -->
        <el-row>
          <div class="grid-content bg-purple-dark step-boxs">
            <div class="">
              <el-steps direction="vertical"
                :active="1"
                space="100%">
                <el-step v-for="item in kdSearchResult.Traces"
                  :key="item.AcceptStation"
                  :title="item.AcceptStation"
                  :description="item.AcceptTime">
                </el-step>

              </el-steps>
            </div>
          </div>
        </el-row>

      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import mapJson from 'static/map.json'
import proviceJson from 'static/provice.json'
import cityJson from 'static/city.json'
import expAreaJson from 'static/area.json'
const URLS = {
  createOrder: 'KD/CreateOrder',
  searchOrder: 'KD/SearchOrder',
  convertAddr: 'BaiduMap/GetConcreteAddress'
}

export default {
  name: 'kdOnlineOrder',
  components: {},
  props: {},
  data () {
    return {
      Loading: false,
      receiverpca: '',
      selectexp: '',
      shipperOptions: [
        // { key: 'auto', value: '智选快递' },
        { key: 'SF', value: '顺丰' },
        { key: 'YTO', value: '圆通' },
        { key: 'ZTO', value: '中通' },
        { key: 'HTKY', value: '百世' },
        { key: 'YD', value: '韵达' },
        { key: 'DBL', value: '德邦' },
        { key: 'UC', value: '优速' }
      ],
      provinceOptions: [],
      cityOptions: [],
      expAreaOptions: [],
      senderCityOptions: [],
      receiverCityOptions: [],
      senderExpAreaOptions: [],
      receiverExpAreaOptions: [],
      autoselectexp: [],
      isNextStepDisable: false,
      isAutoselectexpNext: false,
      kdnLoginvisible: false,
      loginForm: {
        mobile: '',
        phoneCode: ''
      },
      sendCodeDisable: false,
      sendCodeBtnText: '发送验证码',
      RD: {
        WarehouseID: '',
        WarehouseAddress: '',
        CallBack: '',
        MemberID: '',
        ShipperCode: '',
        LogisticCode: '',
        OrderCode: '',
        MonthCode: '',
        PayType: 1, // 1-现付，2-到付，3-月结，4-第三方支付
        ExpType: 1, // 快递类型：1-标准快件
        Cost: '',
        OtherCost: '',
        Receiver: {
          Company: '',
          Name: '',
          Tel: '',
          Mobile: '',
          PostCode: '',
          ProvinceName: '',
          CityName: '',
          ExpAreaName: '',
          Address: ''
        },
        Sender: {
          Company: '',
          Name: '',
          Tel: '',
          Mobile: '',
          PostCode: '',
          ProvinceName: '',
          CityName: '',
          ExpAreaName: '',
          Address: ''
        },
        StartDate: '',
        EndDate: '',
        Weight: 3,
        Quantity: 1,
        Volume: 2,
        Remark: '',
        AddService: [],
        Commodity: []
      },
      addService: {
        Name: '',
        Value: '',
        CustomerID: ''
      },
      commodity: {
        GoodsName: '',
        GoodsCode: '',
        Goodsquantity: 1,
        GoodsPrice: '',
        GoodsWeight: '',
        GoodsVol: '',
        GoodsDesc: ''
      },
      rdSenderPCE: {
        ProvinceCode: '',
        CityCode: '',
        ExpAreCode: ''
      },
      rdReceiverPCE: {
        ProvinceCode: '',
        CityCode: '',
        ExpAreCode: ''
      },
      kdRecord: {
        ShipperCode: '',
        LogisticCode: '',
        OrderCode: ''
      },
      kdSearchResult: {
        LogisticCode: '',
        ShipperCode: '',
        Traces: []
      },
      traces: {
        AcceptStation: '',
        AcceptTime: ''
      }
    }
  },
  methods: {
    async loadMapData () {
      this.provinceOptions = proviceJson
      this.cityOptions = cityJson
      this.expAreaOptions = expAreaJson
      // 数据一样 但相对独立
      this.senderCityOptions = this.cityOptions
      this.senderExpAreaOptions = this.expAreaOptions
      this.receiverCityOptions = this.cityOptions
      this.receiverExpAreaOptions = this.expAreaOptions
    },
    loadParentData () {
      var that = this
      let pdata = this.$store.state.paramOrder.stkOutRow
      let isMob = /^(13[0-9]{9})|(18[0-9]{9})|(14[0-9]{9})|(17[0-9]{9})|(15[0-9]{9})$/
      let isPhone = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/
      let pceMatch = /.+?(省|市|自治区|自治州|县|区)/g

      if (!pdata || !pdata[0]) {
        return
      }
      pdata = pdata[0]
      if (pdata != null && pdata.Address) {
        this.$post(URLS.convertAddr, { Address: pdata.Address }, data => {
          // console.log(data)
          data = JSON.parse(data)
          if (data.Status === 0) {
            let findProv = that.provinceOptions.find(item => {
              return item.value === data.Result.AddressComponent.Province
            })
            let findCity = that.receiverCityOptions.find(item => {
              return item.value === data.Result.AddressComponent.City
            })
            let findExp = that.receiverExpAreaOptions.find(item => {
              return item.value === data.Result.AddressComponent.District
            })
            that.rdReceiverPCE.ProvinceCode = findProv ? findProv.key : ''
            that.rdReceiverPCE.CityCode = findCity ? findCity.key : ''
            that.rdReceiverPCE.ExpAreCode = findExp ? findExp.key : ''
            that.RD.Receiver.ProvinceName = data.Result.AddressComponent.Province
            that.RD.Receiver.CityName = data.Result.AddressComponent.City
            that.RD.Receiver.ExpAreaName = data.Result.AddressComponent.District
          }
        })
      }
      // Receiver
      this.RD.Receiver.Company = pdata.CustomerName
      this.RD.Receiver.Address = pdata.Address
      this.RD.Receiver.Name = pdata.Contact
      this.RD.Receiver.Tel = isPhone.test(pdata.Telephone)
        ? pdata.Telephone
        : ''
      this.RD.Receiver.Mobile = isMob.test(pdata.Telephone)
        ? pdata.Telephone
        : pdata.Mobile
      // sender
      let sAddr = this.$store.state.accountInfo.Address
      let regAddr
      if (sAddr && sAddr !== '') {
        regAddr = sAddr.match(pceMatch)
        if (regAddr && regAddr.length > 0) {
          this.RD.Sender.ProvinceName = regAddr[0]
          this.RD.Sender.CityName = regAddr[1]
          this.RD.Sender.ExpAreaName = regAddr[2]
          this.rdSenderPCE.ProvinceCode = this.getProvinceCodeFromAddr(regAddr[0])
          this.rdSenderPCE.CityCode = this.getCityCodeFromAddr(regAddr[1])
          this.rdSenderPCE.ExpAreCode = this.getExpAreaCodeFromAddr(regAddr[2])
          this.RD.Sender.Address = sAddr.replace(pceMatch, '')
        } else {
          this.RD.Sender.Address = sAddr
        }
      }

      this.RD.Sender.Company = this.$store.state.accountInfo.CompanyName
      this.RD.Sender.Name = this.$store.state.accountInfo.UserName
      this.RD.Sender.Mobile = this.$store.state.accountInfo.Mobile

      // commodity
      // this.RD.ShipperCode = this.$store.state.accountInfo.TransportCompany
      let shippercode = this.shipperOptions.find(item => {
        return item.value === pdata.TransportCompany
      })
      this.RD.ShipperCode = shippercode ? shippercode.key : ''
      this.RD.LogisticCode = pdata.ShippingNo
      this.RD.Remark = pdata.Remark
      if (pdata.ShippingNo) {
        let trans = this.shipperOptions.find(item => {
          return item.value === pdata.TransportCompany
        })
        this.kdRecord.LogisticCode = pdata.ShippingNo
        this.kdRecord.ShipperCode = trans ? trans.key : ''
        this.kdRecord.OrderCode = pdata.BillNo
        // this.onKdSearch()
      }
    },
    loadSenderInfo () {
      this.checkCookie('mySenderInfo')
      // let senderInfo = localStorage['mySenderInfo']
      let senderInfo = this.getCookie('mySenderInfo')
      if (senderInfo) {
        let sender = JSON.parse(senderInfo)
        this.RD.Sender.Name = sender.Name
        this.RD.Sender.Mobile = sender.Mobile
        this.RD.Sender.Address = sender.Address
        this.RD.Sender.ProvinceName = sender.ProvinceName
        this.RD.Sender.CityName = sender.CityName
        this.RD.Sender.ExpAreaName = sender.ExpAreaName
        this.rdSenderPCE.ProvinceCode = this.getProvinceCodeFromAddr(sender.ProvinceName)
        this.rdSenderPCE.CityCode = this.getCityCodeFromAddr(sender.CityName)
        this.rdSenderPCE.ExpAreCode = this.getExpAreaCodeFromAddr(sender.ExpAreaName)
        this.RD.ShipperCode = sender.ShipperCode
      }
    },
    // 选省
    senderChoseProvince: function (e) {
      this.RD.Sender.ProvinceName = e.value
      let provCode = e.key.substring(0, 2)
      let cityOptions = _.filter(this.cityOptions, (item) => { return item.key.substring(0, 2) === provCode })
      this.senderCityOptions = cityOptions
      this.rdSenderPCE.CityCode = cityOptions[0].key
      this.RD.Sender.CityName = cityOptions[0].value
      this.senderChoseCity(cityOptions[0])
    },
    // 选市
    senderChoseCity: function (e) {
      this.RD.Sender.CityName = e.value
      let cityCode = e.key.substring(0, 4)
      let areaOptions = _.filter(this.expAreaOptions, (item) => { return item.key.substring(0, 4) === cityCode })
      this.senderExpAreaOptions = areaOptions
      this.rdSenderPCE.ExpAreCode = areaOptions[0].key
      this.RD.Sender.ExpAreaName = areaOptions[0].value
    },
    // 选区
    senderchoseBlock: function (e) {
      this.RD.Sender.ExpAreaName = e.value
    },
    receiverChoseProvince: function (e) {
      this.RD.Receiver.ProvinceName = e.value
      let provCode = e.key.substring(0, 2)
      let cityOptions = _.filter(this.cityOptions, (item) => { return item.key.substring(0, 2) === provCode })
      this.receiverCityOptions = cityOptions
      this.rdReceiverPCE.CityCode = cityOptions[0].key
      this.RD.Receiver.CityName = cityOptions[0].value
      this.receiverChoseCity(cityOptions[0])
    },
    receiverChoseCity: function (e) {
      this.RD.Receiver.CityName = e.value

      let cityCode = e.key.substring(0, 4)
      let areaOptions = _.filter(this.expAreaOptions, (item) => { return item.key.substring(0, 4) === cityCode })
      this.receiverExpAreaOptions = areaOptions
      this.rdReceiverPCE.ExpAreCode = areaOptions[0].key
      this.RD.Receiver.ExpAreaName = areaOptions[0].value
    },
    receiverChoseBlock: function (e) {
      this.RD.Receiver.ExpAreaName = e.value
    },
    getProvinceCodeFromAddr (prov) {
      if (!prov || prov === '') { return }
      let provObj = this.provinceOptions.find(item => {
        return item.value === prov
      })
      if (provObj && provObj.key) {
        return provObj.key
      }
    },
    getCityCodeFromAddr (city) {
      if (!city || city === '') { return }
      let cityObj = this.senderCityOptions.find(item => {
        return item.value === city
      })
      if (cityObj && cityObj.key) {
        return cityObj.key
      }
    },
    getExpAreaCodeFromAddr (exp) {
      if (!exp || exp === '') { return }
      let expObj = this.senderExpAreaOptions.find(item => {
        return item.value === exp
      })
      if (expObj && expObj.key) {
        return expObj.key
      }
    },
    // onTTCallback () {
    //   let data = JSON.parse('{"EBusinessID": "1237100","Success": true,"Order": {"OrderCode": "012657018199","ShipperCode": "SF","LogisticCode": "123123"},"ResultCode": "100","Reason": ""}')
    //   this.$emit('kdsCallBack', data)
    // },
    onNextStep () {
      this.Loading = true
      this.isNextStepDisable = true
      this.setRDValue()
      if (!this.validateInput()) {
        this.isNextStepDisable = false
        this.Loading = false
        return
      }
      // localStorage.removeItem('mySenderInfo')
      // localStorage.setItem('mySenderInfo', JSON.stringify(this.RD.Sender))
      let cookieData = _.extend(
        { ShipperCode: this.RD.ShipperCode },
        this.RD.Sender
      )
      this.setCookie('mySenderInfo', JSON.stringify(cookieData), 30)

      this.$post(URLS.createOrder, this.RD, data => {
        // console.log(data)
        // debugger
        data = JSON.parse(data)
        // data.Success = true
        // data.Order.LogisticCode = '0244200243420'

        if (data.Success) {
          if (data.Order.LogisticCode) {
            // 回写‘快递单号’ 预约取件只有顺丰会返回快递单号， 自己也不能指定快递单号的，是由快递员提供
          }
          this.$emit('kdsCallBack', data)
          this.$message({ message: '下单成功！', type: 'success' })
          this.$confirm(
            '快递员已接收到您的订单(' +
              data.Order.LogisticCode +
              ')，预计在2小时之内联系您并上门揽件。您也可以通过 订单记录查询快递网点/快递员联系方式，主动联系他们沟通',
            '订单提交成功！',
            {
              confirmButtonText: '确定',
              cancelButtonText: '继续发件',
              type: 'success'
            }
          )
        } else {
          this.$message({
            message: '下单没有成功，原因：' + data.Reason,
            type: 'error'
          })
        }
        this.isNextStepDisable = false
        this.Loading = false
      })
    },
    onKdSearch () {
      // console.log(
      //   this.kdRecord.ShipperCode,
      //   this.kdRecord.LogisticCode,
      //   this.kdRecord.OrderCode
      // )
      if (this.kdRecord.ShipperCode.length < 2) {
        this.$message({ message: '请选择发货的快递公司！', type: 'warning' })
        return
      }
      if (this.kdRecord.LogisticCode < 2) {
        this.$message({ message: '请输入物流单号！', type: 'warning' })
        return
      }
      this.$post(
        URLS.searchOrder,
        {
          OrderCode: this.kdRecord.OrderCode,
          ShipperCode: this.kdRecord.ShipperCode,
          LogisticCode: this.kdRecord.LogisticCode
        },
        data => {
          let result = JSON.parse(data)
          if (result.Success) {
            this.kdSearchResult.LogisticCode = result.LogisticCode
            this.kdSearchResult.ShipperCode = result.ShipperCode
            this.kdSearchResult.Traces = result.Traces.reverse()
          }
          // console.log(this.kdSearchResult)
          // State物流状态：2-在途中,3-签收,4-问题件
          if (result.State === '2') {
            this.$message({ message: '在途中', type: 'success' })
          }
          if (result.State === '3') {
            this.$message({ message: '件已被签收', type: 'success' })
          }
          if (result.State === '4') {
            this.$message({ message: '问题件', type: 'success' })
          }
          if (result.State === '0') {
            this.$message({ message: result.Reason, type: 'success' })
          }
        }
      )
    },
    validateInput () {
      var isvalided = true
      if (this.RD.Sender.Name.length < 1) {
        this.$message({ message: '请输入寄件人姓名！', type: 'warning' })
        isvalided = false
        return isvalided
      }
      if (!/^[1]+[3-8]+\d{9}$/gi.test(this.RD.Sender.Mobile)) {
        this.$message({
          message: '请输入寄件人正确的手机号！',
          type: 'warning'
        })
        isvalided = false
        return isvalided
      }
      if (
        this.RD.Sender.ProvinceName.length < 3 ||
        this.RD.Sender.CityName.length < 3 ||
        this.RD.Sender.ExpAreaName.length < 3
      ) {
        this.$message({ message: '请选择完整寄件人省市区！', type: 'warning' })
        isvalided = false
        return isvalided
      }
      if (this.RD.Sender.Address.length <= 0) {
        this.$message({ message: '寄件人详细地址必填！', type: 'warning' })
        isvalided = false
        return isvalided
      }
      if (this.RD.Receiver.Name.length < 1) {
        this.$message({ message: '请输入收件人姓名！', type: 'warning' })
        isvalided = false
        return isvalided
      }
      if (!/^[1]+[3-8]+\d{9}$/gi.test(this.RD.Receiver.Mobile)) {
        this.$message({
          message: '请输入收件人正确的手机号！',
          type: 'warning'
        })
        isvalided = false
        return isvalided
      }

      if (
        this.RD.Receiver.ProvinceName.length < 3 ||
        this.RD.Receiver.CityName.length < 3 ||
        this.RD.Receiver.ExpAreaName.length < 3
      ) {
        this.$message({ message: '请选择完整收件人省市区！', type: 'warning' })
        isvalided = false
        return isvalided
      }
      if (this.RD.Receiver.Address.length <= 0) {
        this.$message({ message: '收件人详细地址必填！', type: 'warning' })
        isvalided = false
        return isvalided
      }
      if (this.RD.ShipperCode.length <= 0) {
        this.$message({ message: '请选择物流公司！', type: 'warning' })
        isvalided = false
        return isvalided
      }
      if (this.commodity.GoodsName.length <= 0) {
        this.$message({ message: '物品信息必填!', type: 'warning' })
        isvalided = false
        return isvalided
      }
      return isvalided
    },
    setRDValue () {
      // this.RD.AddService[0] = this.addService
      this.RD.Commodity[0] = this.commodity
    },
    // 设置cookie
    setCookie: function (cname, cvalue, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = 'expires=' + d.toUTCString()
      // console.info(cname + '=' + cvalue + '; ' + expires)
      document.cookie = cname + '=' + cvalue + '; ' + expires
      // console.info(document.cookie)
    },
    // 获取cookie
    getCookie: function (cname) {
      var name = cname + '='
      var ca = document.cookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1)
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length)
      }
      return ''
    },
    // 清除cookie
    clearCookie: function (cname) {
      this.setCookie(cname, '', -1)
    },
    checkCookie: function (cname) {
      var sender = this.getCookie(cname)
      if (sender !== '') {
        // console.log(sender)
      } else {
        this.setCookie(cname, sender, 30)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadParentData()
      this.loadSenderInfo()
    })
    this.$emit('load-complete')
  },
  created: function () {
    this.loadMapData()
  }
}
</script>

<style lang="scss">
.kdcontainer {
  font-size: 12px;
  .title_msg {
    border: 1px solid #edd28a;
    margin: 0px 5px 0px 5px;
    font-size: 12px;
    padding-left: 5px;
    background: #fcf8e2;
    line-height: 24px;
    color: #989594;
  }

  .b {
    font-weight: bold;
  }
  .grid-content {
    padding: 10px;
    .znl-row {
      line-height: 38px;
      font-size: 14px;
    }
  }

  .autoselectexp {
    table {
      width: 100%;
    }
    .table-th {
      text-align: left;
      background-color: #d3d4d8;
      border: none;
      font-weight: 600;
      color: #666;
    }
    .table-item1 {
      width: 15%;
    }
    .table-td {
      // padding: 20px 10px;
      text-align: left;
      vertical-align: middle;
      border: none;
    }
  }
  .login-dialog {
    padding: 10px;
    .title {
      text-align: center;
      padding: 20px;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .content {
      .znl-component-input {
        height: 40px;
        width: 165px;
        // padding: 15px;
        padding-left: 25px;
      }
      .phoneCode-btn {
        text-align: left;
        padding-top: 6px;
        padding-left: 30px;
      }
      .validate-code-btn {
        padding: 15px 0 10px 35px;
      }
    }
  }
  .orderRecord {
    .step-boxs {
      height: 420px;
      overflow: auto;
      padding: 5px 23px;
      font-size: 14px;
    }
  }

  .tab-btnstyle-box {
    .el-tabs__content {
      .el-tab-pane {
        .el-row {
          margin: 0 !important;
        }
      }
    }
  }
  .tab-btnstyle-box > .el-tabs__header {
    background: transparent;
  }
  .el-tab-pane {
    // border: 1px solid #cccccc;
    //border: 1px solid red;
  }
}
</style>
