<template>
  <!-- 发货单 -->
  <div class="module-wrapper m-stk-out-order">
    <znl-table
      ref="table"
      v-loading="loading"
      :columns="columns"
      :drag-done="onDragDone"
      :header-menus="getResetTableHeaderMenu('StkOut')"
      :is-fields-search="isShowFiedsSearch"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-index="pageIndex"
      :page-size="pageSize"
      :search-fields="onSearch"
      :show-title-menus="true"
      :showCheck="true"
      :total-count="totalCount"
      :totalField="['Amount', 'AmountReceivable', 'GrossProfit']"
      box-class="m-stk-out-order"
      checkbox-binding-key="StkOutGUID"
      element-loading-text="数据加载中,请稍后..."
      gridtype="base"
      @dbclick="onDbclick"
      @click-link="clicklink"
      @current-row-change="onCurrentRowChangeHandle"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
      @sort-change="sortChangeHandle"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            v-model="fieldsFilter.inputModel"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="输入型号"
            size="mini"
            type="text"
            width="110px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <znl-input
            v-model="fieldsFilter.soBillNo"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="销售单号"
            size="mini"
            type="text"
            width="110px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <div class="m-accurate-checbox znl-component-input">
            <znl-input
              v-model="fieldsFilter.CustomerName"
              :border="true"
              :disable="false"
              class="box_input_checkbox"
              from-type="input"
              inp-perc="100%"
              layout="left"
              placeholder="客户名称"
              size="mini"
              type="text"
              width="160px"
              @keyup.enter.native="onSearch()"
            ></znl-input>
            <el-checkbox
              v-model="fieldsFilter.accurate"
              class="accurate-checbox"
              size="small"
              >精确</el-checkbox
            >
          </div>
          <znl-input
            v-model="fieldsFilter.startOutOn"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="datepicker"
            inp-perc="100%"
            layout="left"
            placeholder="发货时间从"
            size="mini"
            value-format="yyyy-MM-dd"
            width="90px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <znl-input
            v-model="fieldsFilter.endOutOn"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="datepicker"
            inp-perc="100%"
            layout="left"
            placeholder="发货时间止"
            size="mini"
            value-format="yyyy-MM-dd"
            width="90px"
            @keyup.enter.native="onSearch()"
          ></znl-input>

          <znl-input
            v-model="filterEx.ExpectReceiptDate"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="datepicker"
            inp-perc="100%"
            layout="left"
            placeholder="预计收款日"
            size="mini"
            value-format="yyyy-MM-dd"
            width="90px"
            @keyup.enter.native="onSearch()"
          ></znl-input>

          <znl-input
            v-model="filterEx.ReceiveOnStart"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="datepicker"
            inp-perc="100%"
            layout="left"
            placeholder="收款时间从"
            size="mini"
            value-format="yyyy-MM-dd"
            width="90px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <znl-input
            v-model="filterEx.ReceiveOnEnd"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="datepicker"
            inp-perc="100%"
            layout="left"
            placeholder="收款时间止"
            size="mini"
            value-format="yyyy-MM-dd"
            width="90px"
            @keyup.enter.native="onSearch()"
          ></znl-input>

          <!-- 选择状态 -->
          <znl-input
            v-model="filterEx.FilterStatus"
            :border="true"
            :clearable="false"
            :disabled="false"
            :height="22"
            :multiple="true"
            :select-options="filterEx.StatusSelectOptions"
            :tags-clear="false"
            form-type="select"
            layout="left"
            placeholder="-选择状态-"
            popper-class="popperClass"
            width="90px"
            @change="
              (val) => {
                filterEx.FilterStatus = val;
              }
            "
          ></znl-input>

          <znl-input
            v-model="filterEx.Keywords"
            :border="true"
            :clearable="false"
            :disable="false"
            class="box_input_checkbox"
            from-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="搜索关键值"
            size="mini"
            type="text"
            width="100px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <znl-input
            ref="CurrencyCode"
            v-model="filterEx.KeywordsField"
            :border="true"
            :clearable="true"
            :select-options="filterEx.KeywordsFieldOptions"
            form-type="select"
            layout="left"
            placeholder="-关键字-"
            popper-class="popperClass"
            title-width="70px"
            width="70px"
            @keyup.enter.native="onSearch()"
          ></znl-input>

          <znl-input
            v-model="filterEx.StartCreateTime"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="datepicker"
            inp-perc="100%"
            layout="left"
            placeholder="制单时间从"
            size="mini"
            value-format="yyyy-MM-dd"
            width="100px"
            @keyup.enter.native="onSearch()"
          ></znl-input>

          <znl-input
            v-model="filterEx.EndCreateTime"
            :border="true"
            :clearable="true"
            :disabled="false"
            form-type="datepicker"
            inp-perc="100%"
            layout="left"
            placeholder="制单时间止"
            size="mini"
            value-format="yyyy-MM-dd"
            width="100px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
        </el-row>

        <el-row>
          <znl-button
            class="box-left-btn search-btn"
            style-type="mac"
            type="multiple"
          >
            <znl-button-menu @click="onSearch()">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
            <znl-button-menu
              :width="20"
              tip="高级搜索"
              @click="onShowFiedsSearch()"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            v-if="isShowAdd && !this.onlySalesPurchaseOrder"
            style-type="main"
            @click="onStkoutAdd"
          >
            <i class="iconfont icon-add_btn_ic"></i>
            <span>添加</span>
          </znl-button>

          <znl-button
            v-if="isMainAccount || hasRes('Delete')"
            style-type="mac"
            @click="onPostDelete()"
          >
            <i class="iconfont icon-delete_btn_ic"></i>
            <span>删除</span>
          </znl-button>

          <znl-button
            v-if="isMainAccount || (hasRes('Out') && !hasRes('UnOut'))"
            :disabled="postStatus.stkOut"
            style-type="mac"
            @click="onPostStorage()"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>出库</span>
          </znl-button>

          <znl-button
            v-if="isMainAccount || hasRes('Cancel')"
            :disabled="postStatus.cancel"
            style-type="mac"
            @click="onPostCancelAnOrder()"
          >
            <i class="iconfont icon-backout_btn_ic"></i>
            <span>撤单</span>
          </znl-button>

          <znl-button v-if="isShowRec" style-type="mac" @click="onBatchRec">
            <i class="iconfont icon-table_money_ic"></i>
            <span>批量收款</span>
          </znl-button>
          <znl-button
            v-if="isShowImport && !this.onlySalesPurchaseOrder"
            style-type="mac"
            @click="onImport"
          >
            <i class="iconfont icon-leading-in-wh_btn_ic"></i>
            <span>导入</span>
          </znl-button>

          <znl-button
            :btns="printBtns"
            :height="22"
            :isShow="hasRes('Print')"
            style-type="mac"
            @click="onPrint"
          >
            <i class="iconfont icon-print_btn_ic"></i>
            <span>打印</span>
          </znl-button>

          <print-label-button
            template-type="StkOutLabel"
            @get-selected="getCheckedRows"
            v-if="showPrintLabelButton"
          ></print-label-button>

          <znl-button
            v-if="hasRes('Audit')"
            :disabled="postStatus.audit"
            style-type="mac"
            @click="onPostExamine()"
          >
            <i class="iconfont icon-admin_user_ic"></i>
            <span>审核</span>
          </znl-button>

          <znl-button
            v-if="hasRes('UnAudit')"
            :disabled="postStatus.unAudit"
            style-type="mac"
            @click="onPostAntiTrial()"
          >
            <i
              class="iconfont icon-backout_btn_ic"
              style="font-weight: bold"
            ></i>
            <span>反审</span>
          </znl-button>

          <!-- <znl-button v-if="true" style-type="mac" @click="onKdOnlineOrder">
            <i class="iconfont icon-send_btn"></i>
            <span>发快递</span>
          </znl-button>-->
          <znl-button style-type="mac" @click="onEditOtherFees">
            <i class="iconfont icon-leading-in-wh_btn_ic"></i>
            <span>添加其它费用</span>
          </znl-button>

          <znl-button style-type="main" @click="onFreightCalculator">
            <span>运费计算器</span>
          </znl-button>
        </el-row>
      </div>
    </znl-table>

    <!-- 发货单打印设置 -->
    <znl-print
      :is-get-default-param="printInfo.isGetDefaultParam"
      :printInfo="printInfo"
      :visible.sync="znlPrintVisible"
      print-type="STK_Out"
      title="发货单打印设置"
      @set-print-param="setPrintParam"
      @confirm-print="confirmPrint"
    ></znl-print>

    <!--日志dialog-->
    <operate-log
      v-if="operateLogVisible"
      ref="OperateLog"
      :orderGuid="orderGuid"
      :refreshLog="refreshLog"
      :visible="operateLogVisible"
      @close="operateLogVisible = false"
    ></operate-log>

    <!-- 销售发票新增_编辑 -->
    <fin-so-invoice-edit
      v-if="invoiceVisible"
      :accountVisible="invoiceVisible"
      :isReturninv="isReturninvoice"
      :title="invoiceTitle"
      @close="invoiceVisible = false"
      @save-success="onRefresh"
    ></fin-so-invoice-edit>

    <stk-batch-receive
      v-if="payVisible"
      ref="StkBatchReceive"
      :custName="custName"
      :isShowPagination="isShowPagination"
      :visible="payVisible"
      @close="
        () => {
          (payVisible = false), (this.stkOutGUIDS = null), this.onRefresh();
        }
      "
      @confirm="
        (val) => {
          val && (payVisible = false),
            this.onRefresh(),
            (this.stkOutGUIDS = null);
        }
      "
    ></stk-batch-receive>

    <stk-out-edit
      v-if="stkOutEditVisible"
      :edit-type="editType"
      :editvisible="stkOutEditVisible"
      @close="stkOutEditVisible = false"
      @confirm="stkOutEditVisible = false"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
    ></stk-out-edit>
    <stk-other-fees
      v-if="stkOtherFeesVisible"
      ref="StkOtherFees"
      :BillNo="OtherFeesBillNo"
      :InTypes="'1,3'"
      :OrderType="1"
      :editvisible="stkOtherFeesVisible"
      @close="stkOtherFeesClose"
      @confirm="stkOtherFeesVisible = false"
    ></stk-other-fees>
    <!--销售退货-->
    <sales-order-return-edit
      v-if="SalesOrderRetrunVisible"
      :edit-type="editType"
      :editvisible="SalesOrderRetrunVisible"
      @close="SalesOrderRetrunVisible = false"
      @confirm="SalesOrderRetrunVisible = false"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
    ></sales-order-return-edit>

    <znl-dialog
      v-if="kdonVisible"
      :is-footer-show="false"
      :loading="kddLoading"
      :lock-scroll="true"
      :visible.sync="kdonVisible"
      height="575"
      loading-text="数据加载中"
      param-string-tips
      title="快递订单"
      width="730"
    >
      <kd-online-order
        @kdsCallBack="kdsCallBack"
        @load-complete="
          () => {
            this.kddLoading = false;
          }
        "
      ></kd-online-order>
    </znl-dialog>

    <!-- <form-printer
      ref="formPrinter"
      :page-config="pageConfig"
      :row-config="rowConfig"
      :data-sources="dataLine"
      :info="dataInfo"
      v-show="true"
      id="printer"
      style="width:300px; height:400px"
    ></form-printer>-->
    <printer-dialog
      v-if="printerDialogVisible"
      ref="printerDialog"
      :dataInfo="printDataInfo"
      :dataLine="printDataLine"
      :table-name="printInfo.template"
      :visible="printerDialogVisible"
      @close="printerDialogVisible = false"
    ></printer-dialog>

    <znl-data-import
      :show="zznlShowDataImport"
      :type="zznlImportType"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import OperateLog from "@c_modules/Log/OperateLog";
import StkBatchReceive from "@c_modules/Fin/OutReceive";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates.vue";
import { printInfoMixin } from "@c_common/znlGrid/commonComponents/common";
import SalesOrderReturnEdit from "@c_modules/Sale/SalesOrderReturnEdit";
import FinSoInvoiceEdit from "@c_modules/Fin/SalesInvoice/InvoiceEdit";
import KdOnlineOrder from "@c_modules/StkExt/KdOnlineOrder";
import { erpTableSetHandler } from "@scripts/methods/common";
import StkOtherFees from "@c_modules/Stk/StkOtherFees";
import printLabelButton from "@c_common/print/printLabelButton.vue";

// import FormPrinter from "@c_common/print/FormPrinter";
// import PrintConfig from "@c_common/print/config";
// import PrintData from "@c_common/print/data";
import printerDialog from "@c_common/print/printerDialog";
import { isClient } from "~/lib/runtime";
import * as urls from "~/lib/urls";

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  PK: "StkOutGUID",
  configURL: "StkOut/GetConfig",
  resetParamConfigURL: "StkOut/ResetParamConfig",
  searchURL: "StkOut/Search",
  outStock: "StkOut/OpeOut",
  onCancelAnOrderURL: "StkOut/CancelAnOrder",
  onExamineURL: "StkOut/Examine",
  onAntiTrialURL: "StkOut/AntiTrial",
  onAbortStkoutURL: "StkOut/AbortStkOut",
  onDeleteURL: "StkOut/DeleteList",
  searchSumAmountURL: "StkBatchPay/SumAmount",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  saveURL: "StkOut/UpdateList", // 批量保存
  updataURL: "StkOut/Update",
  StkOutSearchById: "StkOutAdd/Search",
  WebPrintURL: "WebPrint/StkOutPrint",
  DownloadPrintFile: "WebPrint/DownloadPrintFile",
  GetParameterUrl: "PubParameter/GetParameter", // 获取系统参数
};
export default {
  name: "ZNLStkOut",
  config: CONFIG,
  mixins: [erpTableSetHandler, getCommonDataMixin, printInfoMixin],
  components: {
    OperateLog,
    StkBatchReceive,
    StkOutEdit,
    ZnlPrint,
    SalesOrderReturnEdit,
    FinSoInvoiceEdit,
    KdOnlineOrder,
    StkOtherFees,
    printerDialog,
    printLabelButton,
    // FormPrinter
  },
  data() {
    return {
      isShowFiedsSearch: false,
      editType: "addNew",
      stkOutEditVisible: false,
      dialogCompanySelectVisible: false,
      SalesOrderRetrunVisible: false,
      stkOutGUID: "", // 需要传递给编辑页面的GUID
      stkOutAddVisible: false, // 是否显示出库编辑页面
      invoiceVisible: false, // 销售发票
      isReturninvoice: false, // 是否冲红发票
      invoiceTitle: "",
      columns: [],
      itemSource: [],
      stkOtherFeesVisible: false,
      OtherFeesBillNo: [],
      fieldsFilter: {
        startOutOn: "",
        endOutOn: "",
        inputModel: "",
        CustomerName: "",
        IsAuditedName: "",
        accurate: false,
        customerPartNo: "",
        productCategory: "",
        soBillNo: "",
      },

      filterEx: {
        FilterStatus: [], // 请选择状态
        Keywords: "", // 关键字
        KeywordsField: "", // 关键字对应的列
        StartCreateTime: "", // 制单时间
        EndCreateTime: "", // 制单时间
        ExpectReceiptDate: "", // 预计收款日
        KeywordsFieldOptions: [
          { key: "BillNo", value: "单号" },
          { key: "Shipper", value: "发货员" },
          { key: "ReceiptType", value: "收款方式" },
          { key: "Remark", value: "备注" },
          { key: "Receiver", value: "收款人" },
          { key: "TransportCompany", value: "快递公司" },
          { key: "ShippingNo", value: "快递单号" },
          { key: "TaxRate", value: "税点" },
          { key: "CurrencyCode", value: "币种" },
          // { key: 'TicketNo', value: '发票号' },
          { key: "SalCompany", value: "销售公司" },
          { key: "CreatedByName", value: "制单人" },
          { key: "Contact", value: "联系人" },
          { key: "Telephone", value: "联系电话" },
          { key: "Address", value: "收货地址" },

          { key: "Brand", value: "品牌", other: true },
          { key: "Packaging", value: "封装", other: true },
          { key: "MakeYear", value: "年份", other: true },
          { key: "SupplierInfo", value: "供应商信息", other: true },
          { key: "ModelRemark", value: "型号备注", other: true },
          { key: "CustomerPartNo", value: "客户料号", other: true },
          { key: "DeliveryDate", value: "货期", other: true },
          { key: "InvType", value: "库存类型", other: true },
          { key: "SalesModel", value: "销售型号" },
          { key: "ProductCategory", value: "产品类型", other: true },
          { key: "CustomerOrderNo", value: "客户订单号", other: true },
          { key: "ModelRemark2", value: "备注2", other: true },
          { key: "ModelRemark3", value: "备注3", other: true },
          { key: "StorageName", value: "库位", other: true },
          { key: "OrderFrom", value: "订单来源", other: false },
        ],
        StatusSelectOptions: [
          { key: "已出库", value: "已出库" },
          { key: "未出库", value: "未出库" },
          { key: "已撤单", value: "已撤单" },
          { key: "已收款", value: "已收款" },
          { key: "部分收款", value: "部分收款" },
          { key: "未收款", value: "未收款" },
          { key: "已开票", value: "已开票" },
          { key: "部分开票", value: "部分开票" },
          { key: "未开票", value: "未开票" },
          { key: "含税", value: "含税" },
          { key: "未税", value: "未税" },
          { key: "已审核", value: "已审核" },
          { key: "未审核", value: "未审核" },
        ],
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      isShowPagination: false,
      resourceList: [],
      operateLogVisible: false,
      orderGuid: "",
      stkOutGUIDS: [],
      refreshLog: true,
      payVisible: false,
      showstkBatchReceive: false,
      isFinRecPay: false,
      custName: null,
      parameterConfig: [],
      //CurrencyNames: [],
      TaxRate: [],
      ParamJosn: [],
      IsAutoPrint: false, // 是否自动打印模板
      IsContinuousRecord: false,
      loading: false,
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "", // 打印模板类型
      }, // end 打印模块参数
      auditedOptions: [
        { key: "未审", value: false },
        { key: "已审", value: true },
      ],
      isShowPrint: false,
      isShowAdd: false,
      isShowRec: false,
      isShowImport: false,
      kdonVisible: false,
      shipperOptions: [
        { key: "SF", value: "顺丰" },
        { key: "YTO", value: "圆通" },
        { key: "ZTO", value: "中通" },
        { key: "HTKY", value: "百世" },
        { key: "YD", value: "韵达" },
        { key: "DBL", value: "德邦" },
        { key: "UC", value: "优速" },
      ],
      kddLoading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount, // false 为非主账号
      currencyList: [],
      userList: [],
      taxRateList: [],
      postStatus: {
        // post状态控制，记录后端请求是否已响应
        stkOut: false, // 入库
        delete: false, // 删除
        payment: false, // 付款
        audit: false, // 审核
        unAudit: false, // 反审
        cancel: false, //  撤单
      },
      // rowConfig: PrintConfig.row,
      // pageConfig: PrintConfig.page,
      // dataInfo: PrintData.info,
      // dataLine: PrintData.dataSources,
      printerDialogVisible: false,
      printerDialogTitle: "",
      printDataInfo: {},
      printDataLine: Array,
      printBtns: [
        {
          id: 1,
          name: "默认打印模板设置",
          click: () => {
            this.znlPrintVisible = true;
          },
        },
        {
          id: 2,
          name: "默认打印机设置",
          click: () => {
            this.showPrintSettings("STK_Out");
          },
        },
      ],
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false,
    },
    initStkOutBillNo: {
      type: String,
      default: "",
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {},
        };
      },
    },
  },
  watch: {
    selsectOptionsDemandSources(val) {
      _.each(this.columns, (column) => {
        if (column.binding === "OrderFrom") {
          this.$set(column, "dataMap", val);
        }
      });
    },
  },
  computed: {
    showPrintLabelButton() {
      return this.hasRes("PrintLabel") && isClient();
      // return true;
    },
  },
  async created() {
    if (!isClient()) {
      this.printBtns = this.printBtns.filter((item) => item.id !== 2);
    }
  },
  async mounted() {
    this.currencyList = _.map(
      await this.getCurrencyCodeListAsync,
      function (obj, index) {
        return { key: obj.value, value: obj.key };
      }
    );
    this.userList = _.map(await this.selectOptionsAllUserURL, function (obj) {
      return { key: obj.value, value: obj.value };
    });
    this.taxRateList = await this.getTaxRateListAsync;
    await this.onInit();
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage("stkOut", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      this.paramConfiguration();
      let columns = config.ColumnConfigs;
      this.initColumns(columns);

      columns = this.addMoreButtons(columns);
      this.columns = columns;

      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.isShowPrint = this.isMainAccount || this.hasRes("Print");
      this.isShowAdd = this.isMainAccount || this.hasRes("Add");
      this.isShowRec = this.isMainAccount || this.hasRes("BatchRec");
      this.isShowImport = this.isMainAccount || this.hasRes("OnImport");
      this.parameterConfig = config.ParamJosn;
      if (this.initStkOutBillNo != "")
        this.fieldsFilter.soBillNo = this.initStkOutBillNo;
      this.$nextTick(() => {
        this.$refs.table.init();
        this.loading = false;
        this.$emit("page-loading", false);
      });
      // 设置默认搜索条件
      this.setDefaultSearch();
      if (this.initData) {
        this.onBindData(1);
      }
      // 设置默认打印模板
      let printInfo = this.getPrintInfo(config.ParamJosn);
      this.printInfo.template = printInfo.template;
      this.printInfo.templateType = printInfo.templateType;
      this.printInfo.title = printInfo.title;
    },
    //运费计算器
    async onFreightCalculator() {
      let url = "https://znlscm.com/freightCalculator";
      try {
        const result = await this.$post(CONFIG.GetParameterUrl, {
          ParamCode: "FreightCalculatorUrl",
        });
        if (result && result.CurrentValue) url = result.CurrentValue;
      } catch (ex) {
        console.log("获取运费计算器地址异常：", ex);
      } finally {
        window.open(url);
      }
    },

    addMoreButtons(columns) {
      let mouseBtn = [];
      if (this.isMainAccount || this.hasRes("Edit")) {
        mouseBtn.push({
          title: "编辑",
          name: "Edit",
          click: (row) => {
            this.onStkoutEdit(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        mouseBtn.push({
          title: "删除",
          name: "Delete",
          click: (row) => {
            this.onPostDelete([row]);
          },
        });
      }
      if (this.isMainAccount || (this.hasRes("Out") && !this.hasRes("UnOut"))) {
        mouseBtn.push({
          title: "出库",
          name: "Out",
          click: (row) => {
            this.onPostStorage(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Cancel")) {
        mouseBtn.push({
          title: "撤单",
          name: "Cancel",
          click: (row) => {
            this.onPostCancelAnOrder(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Audit")) {
        mouseBtn.push({
          title: "审核",
          name: "Audit",
          click: (row) => {
            this.onPostExamine([row]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("UnAudit")) {
        mouseBtn.push({
          title: "反审",
          name: "UnAudit",
          click: (row) => {
            this.onPostAntiTrial([row]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Return")) {
        mouseBtn.push({
          title: "退货",
          name: "Return",
          click: (row) => {
            this.onReturn(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("ExPrint")) {
        mouseBtn.push({
          title: "打印快递",
          name: "onExPrint",
          click: (row) => {
            this.onExPrint(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("OperateLog")) {
        mouseBtn.push({
          title: "日志",
          name: "OperateLog",
          click: (row) => {
            this.operateLogVisible = true;
            this.$nextTick(() => {
              this.orderGuid = row.StkOutGUID;
              this.refreshLog = !this.refreshLog;
            });
          },
        });
      }
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
          canSearch: false,
        },
      ];
      return tempConfig.concat(columns);
    },

    initColumns(columns) {
      _.extend(
        _.find(columns, function (column) {
          return column.BindField === "OtherFeeAmount";
        }),
        {
          isReadOnly: true,
          event: "click-link",
          className: "v-link",
        }
      );

      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: this.currencyList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "Shipper");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: this.userList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "Receiver");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: this.userList,
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CreatedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: this.userList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "IsAudited");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: this.auditedOptions,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "Status");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: [
            { key: "未出", value: 1 },
            { key: "已出", value: 3 },
            { key: "已撤", value: 9 },
          ],
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "IsShipped");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: [
            { key: "否", value: false },
            { key: "是", value: true },
          ],
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "OrderFrom");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: this.selsectOptionsDemandSources,
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CustomerName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          OrderBy: "desc",
        });
      }
      //处理特殊权限
      let noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");
      _.remove(columns, (item) => {
        if (noSeeProfit || !viewBuyPrice) {
          if (item.BindField == "GrossProfit")
            // 利润
            return true;
        }
      });
    },

    // 设置默认搜索条件
    setDefaultSearch() {
      let defDate = this.getOrderSearchDefaultDate();
      if (defDate) {
        defDate.start && (this.filterEx.StartCreateTime = defDate.start);
        defDate.end && (this.filterEx.EndCreateTime = defDate.end);
      }
    },

    setBillNo(billNo) {
      this.fieldsFilter = {
        startOutOn: "",
        endOutOn: "",
        inputModel: "",
        CustomerName: "",
        IsAuditedName: "",
        accurate: false,
        customerPartNo: "",
        productCategory: "",
        soBillNo: billNo,
      };
      this.onBindData(1);
    },
    stkOtherFeesClose() {
      this.stkOtherFeesVisible = false;
      this.$refs.table.init();
    },
    clicklink(event) {
      this.OtherFeesBillNo = [];

      this.OtherFeesBillNo.push(event.row.BillNo);

      this.$nextTick(function () {
        this.stkOtherFeesVisible = true;
        this.$refs.table.init();
      });
    },

    //编辑
    onEditOtherFees() {
      var rows = this.$refs.table.getCheckedRows();
      if (rows.length == 0) {
        this.$message({
          message: "请选择需要添加其它费用的发货单",
          type: "error",
        });
        return;
      }

      this.OtherFeesBillNo = [];
      _.each(rows, (item) => {
        this.OtherFeesBillNo.push(item.BillNo);
      });
      this.$store.state.visibled = true;
      this.$nextTick(function () {
        this.stkOtherFeesVisible = true;
      });
    },

    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "StkOut", ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("stkOut");
          }
        );
      }
    },

    async onBindData(pageIndex = 1) {
      let param = {};
      let filterLast = {};
      if (this.filterEx.ExpectReceiptDate) {
        filterLast.ExpectReceiptDate =
          this.filterEx.ExpectReceiptDate +
          " - " +
          this.filterEx.ExpectReceiptDate;
      }
      // 收款时间
      let receiveOn = " - ";
      if (this.filterEx.ReceiveOnStart) {
        receiveOn = this.filterEx.ReceiveOnStart + receiveOn;
      }
      if (this.filterEx.ReceiveOnEnd) {
        receiveOn += this.filterEx.ReceiveOnEnd;
      }
      if (receiveOn !== " - ") {
        filterLast.ReceiveOn = receiveOn;
      }
      // 关键字
      const { Keywords, KeywordsField, KeywordsFieldOptions } = this.filterEx;
      console.log(this.filterEx);
      if (Keywords && KeywordsField) {
        let current = KeywordsFieldOptions.find(
          (item) => item.key === KeywordsField
        );
        if (current) {
          if (current.other) {
            param[KeywordsField] = Keywords;
          } else {
            filterLast[KeywordsField] = Keywords;
          }
        }
      }

      filterLast = _.extend({}, this.fieldsFilter, filterLast);

      // 币种
      if (this.hasValue(filterLast.CurrencyCode)) {
        // 将币种转换成英文 人民币 => RMB
        let cc = _.find(
          this.currencyList,
          (m) => m.key === filterLast.CurrencyCode
        );
        if (cc) {
          filterLast.CurrencyCode = cc.value;
        }
      }
      this.loading = true;
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(filterLast),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          StartOutOn: this.fieldsFilter.startOutOn,
          EndOutOn: this.fieldsFilter.endOutOn,
          InputModel: this.fieldsFilter.inputModel,
          CustomerPartNo: this.fieldsFilter.customerPartNo,
          ProductCategory: this.fieldsFilter.productCategory,
          soBillNo: this.fieldsFilter.soBillNo,
          RecevieTimeStart: this.filterEx.ReceiveOnStart,
          RecevieTimeEnd: this.filterEx.ReceiveOnEnd,
          AllowViewFollowBill:
            this.getCompanySetupValue("AllowViewFollowBill") === "1",
          Status: this.filterEx.FilterStatus,
          IsReturn: false,
          StartCreateTime: this.filterEx.StartCreateTime,
          EndCreateTime: this.filterEx.EndCreateTime,
        },
        param
      );
      return await this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if (this.$refs.table) this.$refs.table.cleanClickRow();
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },

    // 获取参数配置
    paramConfiguration() {
      // 搜索栏状态 0关闭 1打开
      let paramIsQueryBarStatus = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsQueryBarStatus && paramIsQueryBarStatus.ParamValue === "1") {
        this.isShowFiedsSearch = true;
      }

      // 单据保存后自动打印 0是 1否
      let paramIsAutoPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn;
        }
      });
      if (paramIsAutoPrint && paramIsAutoPrint.ParamValue === "0") {
        this.IsAutoPrint = true;
      }
      // 连续录单模式
      let paramIsContinuous = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsContinuous && paramIsContinuous.ParamValue === "1") {
        this.IsContinuousRecord = true;
      }

      // 默认币种
      let paramCurrencyCode = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "CurrencyCode") {
          return paramjosn.ParamValue;
        }
      });
      if (paramCurrencyCode) {
        this.CurrencyCode = paramCurrencyCode.ParamValue;
      }

      // 默认税率
      let paramTaxRate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "TaxRate") {
          return paramjosn.ParamValue;
        }
      });
      if (paramTaxRate) {
        this.TaxRate = paramTaxRate.ParamValue;
      }

      // 打印单据模板
      let paramPrintTemplate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTemplate") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTemplate) &&
        this.hasValue(paramPrintTemplate.ParamValue)
      ) {
        this.printInfo.template = paramPrintTemplate.ParamValue;
      }
      // 打印单据模板的抬头
      let paramPrintTitle = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTitle") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTitle) &&
        this.hasValue(paramPrintTitle.ParamValue)
      ) {
        this.printInfo.title = paramPrintTitle.ParamValue;
      }
      // 类型
      let paramPrintTemplateType = _.find(this.ParamJosn, (item) => {
        if (item && item.ParamCode === "PrintTemplateType") {
          return item.ParamValue;
        }
      });
      if (paramPrintTemplateType) {
        this.printInfo.templateType =
          this.toInt(paramPrintTemplateType.ParamValue) || 1;
      } else {
        this.printInfo.templateType = 1;
      }
    },
    onStkoutAdd() {
      this.$store.state.visibled = false;
      this.editType = "addNew";
      this.stkOutEditVisible = true;
    },
    onSelectRow() {
      // 获取选中的行 先判断勾选再判断选中
      let guid = [];
      return guid;
    },
    // 编辑
    onStkoutEdit(row) {
      if (!this.hasRes("Edit")) {
        return false;
      }
      if (this.hasValue(row) && this.hasValue(row.StkOutGUID)) {
        let paramOrder = { stkOutGUID: row.StkOutGUID };
        this.$store.commit("setParamOrder", paramOrder);
        this.$nextTick(function () {
          this.editType = "edit";
          this.stkOutEditVisible = true;
        });
      }
    },
    // 删除
    onPostDelete(rows = null) {
      if (rows === null) {
        rows = this.$refs.table.getCheckedRows();
      }
      if (!this.hasValue(rows) || rows.length === 0) {
        return this.$message({
          message: "请选中要删除的入库单",
          type: "error",
        });
      }
      let isPass = true;
      rows.forEach((row) => {
        if (isPass) {
          isPass = this.checkData(row, 1);
        }
      });
      if (!isPass) {
        return false;
      }

      let billNo = rows.map((a) => a.BillNo).join(", ");
      let param = {
        ListStkoutGuid: rows.map((item) => item.StkOutGUID),
      };

      this.$confirm("确认删除 单号:" + billNo + " 的发货单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(CONFIG.onDeleteURL, param, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "操作成功", type: "success" });
            this.onRefresh();
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
          return data;
        });
      });
    },
    // 出库
    onPostStorage(row = null) {
      if (this.postStatus.stkOut) {
        return false;
      }
      if (row === null) {
        let rows = this.$refs.table.getCheckedRows();
        if (rows && rows.length > 0) {
          row = rows[0];
        }
      }
      if (!this.hasValue(row) || !this.hasValue(row.StkOutGUID)) {
        return this.$message({
          message: "请选择要操作的发货单",
          type: "error",
        });
      }
      if (this.checkData(row, 5)) {
        this.postStatus.stkOut = true;
        this.$post(
          CONFIG.outStock,
          { StkOutGUID: row.StkOutGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "出库成功", type: "success" });
              this.onRefresh();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        ).finally(() => {
          this.postStatus.stkOut = false;
        });
      }
    },
    // 撤单请求
    onPostCancelAnOrder(row = null) {
      if (this.postStatus.cancel) {
        return false;
      }
      if (row === null) {
        let rows = this.$refs.table.getCheckedRows();
        if (rows && rows.length > 0) {
          row = rows[0];
        }
      }
      if (!this.hasValue(row)) {
        return this.$message({
          message: "请选择要操作的发货单",
          type: "error",
        });
      }
      if (this.checkData(row, 2)) {
        this.postStatus.cancel = true;
        this.$post(
          CONFIG.onCancelAnOrderURL,
          { StkOutGUID: row.StkOutGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "撤单成功", type: "success" });
              this.onRefresh();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        ).finally(() => {
          this.postStatus.cancel = false;
        });
      }
    },
    // 审核请求
    onPostExamine(rows = null) {
      if (this.postStatus.audit) {
        return false;
      }
      if (rows === null) {
        rows = this.$refs.table.getCheckedRows();
      }
      if (rows === null || rows === undefined || rows.length == 0) {
        return this.$message({
          message: "请选择要操作的入库单",
          type: "error",
        });
      }
      let ispass = true;
      rows.some((row) => {
        if (!this.checkData(row, 3)) {
          ispass = false;
          return true;
        }
      });
      if (!ispass) {
        return false;
      }

      let param = {
        ListStkoutGuid: rows.map((r) => r.StkOutGUID),
      };
      this.postStatus.audit = true;
      this.$post(CONFIG.onExamineURL, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: "审核成功", type: "success" });
          this.$refs.table.cleanAllCheck();
          this.onBindData(this.pageIndex);
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
        return data;
      }).finally(() => {
        this.postStatus.audit = false;
      });
    },

    // 反审核请求
    onPostAntiTrial(rows = null) {
      if (this.postStatus.unAudit) {
        return false;
      }
      if (rows === null) {
        rows = this.$refs.table.getCheckedRows();
      }
      if (rows === null || rows === undefined || rows.length == 0) {
        return this.$message({
          message: "请选择要操作的入库单",
          type: "error",
        });
      }
      let ispass = true;
      rows.some((row) => {
        if (!this.checkData(row, 4)) {
          ispass = false;
          return true;
        }
      });
      if (!ispass) {
        return false;
      }

      let param = {
        ListStkoutGuid: rows.map((r) => r.StkOutGUID),
      };
      this.postStatus.unAudit = true;
      this.$post(CONFIG.onAntiTrialURL, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: "反审核成功", type: "success" });
          this.$refs.table.cleanAllCheck();
          this.onBindData(this.pageIndex);
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
      }).finally(() => {
        this.postStatus.unAudit = false;
      });

      // if (!this.hasValue(row)) {
      //   return;
      // }
      // if (this.checkData(row, 4)) {
      //   this.$post(CONFIG.onAntiTrialURL, { ListStkoutGuid: [row.StkOutGUID] }, (data, logic) => {
      //     if (logic.code === 200) {
      //       this.$message({ message: '操作成功', type: 'success' })
      //       this.onBindData(this.pageIndex)
      //     } else {
      //       this.$message({ message: logic.msg, type: 'error' })
      //     }
      //     return data
      //   })
      // }
    },
    // 退货
    onReturn(row) {
      if (row.Status !== 2 && row.Status !== 3) {
        return this.$message({
          message: "单号:【" + row.BillNo + "】的发货单没有出库，不能退货!",
          type: "warning",
        });
      }
      let data = { StkOutGUID: row.StkOutGUID, IsReturn: true };
      this.$store.commit("setParamOrder", { stkOutReturnInfo: data });
      this.$nextTick(function () {
        this.editType = "edit";
        this.SalesOrderRetrunVisible = true;
      });
    },
    // 打印快递
    onExPrint(row) {
      if (!this.hasValue(row)) {
        return;
      }
      let data = {
        CustomerGUID: row.CustomerGUID,
        CustomerName: row.CustomerName,
        Contact: row.Contact,
        Address: row.Address,
        Telephone: row.Telephone,
        Shipper: row.Shipper,
      };
      this.$nextTick(function () {
        this.printExpress(data);
      });
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      this.$refs.table.cleanAllCheck();
      return this.onBindData(1);
    },
    onRefresh() {
      this.$refs.table.cleanAllCheck();
      this.onBindData(this.pageIndex);
      this.onCurrentRowChangeHandle();
    },

    checkData(row, state) {
      // 1.删除 2.撤单 3.审核 4.反审 5.出库 6.发票
      if (state === 1) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + " 】已审，不能删除",
            type: "warning",
          });
          return false;
        }
        if (row.Status === 3 || row.Status === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + " 】已出库或部分出库，不能删除",
            type: "warning",
          });
          return false;
        }
      } else if (state === 2) {
        if (row.Status === 9) {
          this.$message({
            message: "单号:【" + row.BillNo + " 】已撤销，不能重复撤单",
            type: "warning",
          });
          return false;
        }
        if (row.Status === 1) {
          this.$message({
            message: "单号:【" + row.BillNo + " 】未出库，不能撤单",
            type: "warning",
          });
          return false;
        }
        if (row.ReturnStatus === 3 || row.ReturnStatus === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已退货，不能撤单",
            type: "warning",
          });
          return false;
        }
      } else if (state === 3) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + " 】已审核，不能重复审核",
            type: "warning",
          });
          return false;
        }
      } else if (state === 4) {
        if (!row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】未审核，不能反审",
            type: "warning",
          });
          return false;
        }
      } else if (state === 5) {
        if (row.Status === 3) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已全部出库，不能重复出库",
            type: "warning",
          });
          return false;
        }
      } else if (state === 6) {
        if (row.CurrencyCode !== "RMB") {
          this.$message({
            message:
              "单号:【" + row.BillNo + "】币种不是人民币类型，不能开发票",
            type: "warning",
          });
          return false;
        }
        if (!this.hasValue(row.TaxRate) || row.TaxRate <= 0) {
          this.$message({
            message: "单号:【" + row.BillNo + "】税点为0，不能开发票",
            type: "warning",
          });
          return false;
        }
        if (row.StatusName !== "已出库") {
          this.$message({
            message: "单号:【" + row.BillNo + "】未出库，不能开发票",
            type: "warning",
          });
          return false;
        }
        if (
          this.hasValue(row.TicketAmount) &&
          this.hasValue(row.Amount) &&
          row.TicketAmount >= row.Amount
        ) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已全部开票，不可再开票",
            type: "warning",
          });
          return false;
        }
      }
      return true;
    },

    // 验证操作权限
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.templateType = printInfo.templateType;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    async onPrint() {
      //网页版打印
      if (!isClient()) {
        await this.onWebPrint();
        return;
      }

      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkOutGUID)) {
        return this.$message({
          message: "请选中要打印的发货单",
          type: "error",
        });
      }

      console.log(this.printInfo, "print info...");
      let tableName = this.printInfo.template; // 模板ID
      let titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        this.printInfo.isGetDefaultParam = true;
      } else {
        this.printStkOut({
          TableName: tableName,
          Guid: row.StkOutGUID,
          SubCompanyId: titleId,
        });
      }
    },

    async onBatchRec() {
      let rows = this.$refs.table.getCheckedRows();
      if (!this.hasValue(rows) || rows.length === 0) {
        this.$message({ message: "请勾选上要操作的发货单", type: "warning" });
        return false;
      }
      // 过滤掉已全部收款的订单
      let filterRows = [];
      _.each(rows, (row) => {
        let amt = row.Amount || 0;
        let gotAmt = row.ReceivedAmount || 0;
        let discountAmount = row.DiscountAmount || 0;
        if (amt !== 0 && amt !== gotAmt + discountAmount) {
          filterRows.push(row);
        }
      });
      if (filterRows.length === 0) {
        this.$message({ message: "您勾选的单据没有未收款项", type: "warning" });
        return false;
      }
      rows = filterRows;
      this.custName = null;
      let isRep = false; // 是否有多个客户名称
      let notOut = false; // 是否有未出库
      let isContinue = true; // 是否继续
      _.each(rows, (r) => {
        if (this.custName === null) {
          this.custName = r.CustomerName;
        }
        if (this.custName !== r.CustomerName) {
          isRep = true;
        }
        if (r.StatusName === "未出库") {
          notOut = true;
        }
      });
      if (isRep) {
        await this.$confirm("你选择的发货单包含多个客户, 是否继续?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            isContinue = true;
            // 多个客户时 不显示预收款,把客户名称清空
            this.custName = null;
          })
          .catch(() => {
            isContinue = false;
          });
      }
      if (notOut && isContinue) {
        await this.$confirm("你选择的发货单含有未出库的, 是否继续?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            isContinue = true;
          })
          .catch(() => {
            isContinue = false;
          });
      }
      if (isContinue) {
        let guids = _.map(rows, (item) => {
          return item.StkOutGUID;
        });
        let paramOrder = { StkOutGUIDS: guids };
        this.$store.commit("setParamOrder", paramOrder);
        this.$nextTick(() => {
          this.payVisible = true;
        });
      }
    },
    onImport: async function () {
      // 导入出库单
      this.$nextTick(function () {
        this.importData({ importType: "stkout" });
      });
    },

    // 双击事件
    onDbclick(e) {
      if (e.column.BindField === "BillNo") {
        this.onStkoutEdit(e.rowData);
      }
    },

    hasSpecialRes(code) {
      return _.some(
        this.selectOptionsSpecialResource,
        (page) => page.Code === code
      );
    },
    onBatchTicket() {
      let rows = this.$refs.flexGrid.getSelectedRows();
      let flag = true;
      let stkOutGUIDs = [];
      let custName = null;
      let isSameCust = true;
      if (rows === null || rows === undefined || rows.length <= 0) {
        return this.$message({
          message: "请勾选上要开发票的数据行!",
          type: "warning",
        });
      }
      _.each(rows, (item) => {
        if (custName === null) {
          custName = item.CustomerName;
        }
        if (custName !== item.CustomerName) {
          isSameCust = false;
          return;
        }

        if (this.checkData(item, 6)) {
          stkOutGUIDs.push(item.StkOutGUID);
        } else {
          flag = false;
          return;
        }
      });
      if (!isSameCust) {
        return this.$message({
          message: "请选择相同的客户名称,再操作!",
          type: "warning",
        });
      }
      if (flag) {
        this.showInvoiceForm(stkOutGUIDs);
      }
    },
    showInvoiceForm(val) {
      this.$store.commit("setParamOrder", { StkOutGUIDS: val });
      this.$nextTick(function () {
        this.invoiceTitle = "新增销售发票";
        this.invoiceVisible = !this.invoiceVisible;
        this.isReturninvoice = false; // 生成发票，不是冲红
      });
    },
    onKdOnlineOrder() {
      let row = [];
      let rows = this.$refs.table.getCheckedRows();
      if (rows.length <= 0) {
        return this.$message({
          message: "请勾选上要开发快递的单据!",
          type: "warning",
        });
      }
      row = rows[0];
      this.$store.commit("setParamOrder", { stkOutRow: row });
      this.kdonVisible = true;
      this.kddLoading = true;
    },
    kdsCallBack(data) {
      let row = this.$refs.flexGrid.getSelectedRows()[0];
      if (!row || !data) {
        return;
      }
      row.IsShipped = true;
      if (this.hasValue(data.Order.LogisticCode)) {
        row.ShippingNo = data.Order.LogisticCode;
      }
      if (this.hasValue(data.Order.ShipperCode)) {
        row.TransportCompany = _.find(this.shipperOptions, (item) => {
          return item.key === data.Order.ShipperCode;
        }).value;
      }
      if (this.lineList) {
        row.LineList = this.lineList;
      }
      this.$post(CONFIG.updataURL, row, (sres) => {
        console.log(sres);
      });
    },

    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      } else {
        row = this.$refs.table.getActiveRow();
      }
      if (row && this.hasValue(row.StkOutGUID)) {
        this.$emit("selection-changed", row);
      }
    },
    sortChangeHandle(e) {
      console.log("e", e);
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkOut", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkOut", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkOut", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkOut");
    },
    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkOutGUID)) {
        return this.$message({
          message: "请选中要打印的发货单",
          type: "error",
        });
      }

      let tableName = this.printInfo.template; // 模板ID
      let titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
        return;
      }
      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.StkOutGUID,
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          if (data) {
            let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
            window.open(downloadUrl); // 下载文件
          }
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
      });
    },

    getCheckedRows(callback) {
      let rows = this.$refs.table.getCheckedRows();
      callback(rows);
    },
  },
};
</script>

<style lang="scss">
</style>
