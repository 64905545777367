<template>
  <div class="ShoppingCart" :style="shoppingStyle" :class="[visible ? '' : 'shopping--cart-hide']">
    <div class="btnShoppingRow">
      <div
        class="btnShopping"
        :class="{btnShoppingOnlyBtn: !visible, isWebShow: !isClient}"
        @click="$emit('update:visible', !visible)"
      >
        <span>我的购物车</span>
        <i class="iconfont icon-save_btn_ic">
          <span class="quantity">
            <span class="quantity-value">{{quantity}}</span>
          </span>
        </i>
      </div>
    </div>
    <div class="overlayer" :style="shoppingStyle">
      <div class="shopping-box" :class="[visible ? '' : 'shopping-box-hide']">
        <div class="none-text" v-if="items.length===0">您当前没有添加购物车!</div>

        <el-row v-else class="shopping-head">
          <span>最新加入的物料</span>
        </el-row>

        <el-row v-for="(item, index) in items" :key="index" class="shopping-row">
          <div class="shopping-row-title clearfix">
            <span class="CompanyName">{{item.CompanyName}}</span>
            <znl-button
              class="btnOrder"
              style-type="main"
              :height="22"
              :width="70"
              @click="()=>{generatePurchase(item)}"
            >生成采购单</znl-button>
          </div>
          <znl-lists
            type="bomai"
            ref="bomaiList"
            :on-init="onInit"
            :columns="columns"
            :item-source="item.list"
            :on-search="onSearch"
            :show-footer="false"
            :is-multi-rows-check="false"
          ></znl-lists>
        </el-row>

        <el-row slot="footer" style="display: none;">
          <znl-button type="default" :height="33" :width="60" @click="onCancel">关闭</znl-button>
        </el-row>
      </div>
      <purchase-order-edit-dialog
        :editvisible="purchaseEditVisible"
        v-if="purchaseEditVisible"
        :title="purchaseTitle"
        @close="onPruchaseEditClose"
        @clear-cart="(val)=>{onClearCart(val)}"
        :shoppingCartItem="carItem"
      ></purchase-order-edit-dialog>
    </div>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { shppingColumns } from "./columnsConfig";
import { mapState } from "vuex";
import purchaseOrderEditDialog from "@c_modules/Purchase/OrderEdit";
import {isClient} from "~/lib/runtime";


export default {
  components: {
    purchaseOrderEditDialog,
  },
  name: "StkOutLineRecord",

  mixins: [znlMethodsMixin],

  computed: {
    ...mapState({
      shoppingData: (state) => state.shoppingData,
    }),
    shoppingStyle() {
      let style = {};
      style.maxHeight = this.$store.state.domRect.clientHeight - 100 + "px";
      return style;
    },
    quantity() {
      let value = this.$store.state.shoppingData.length;
      if(value > 99) value ='99+';
      return value;
    },
  },

  data() {
    return {
      config: {},
      columns: [],
      dialogShow: true,
      itemSource: [],
      items: [{}],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      purchaseEditVisible: false,
      carItem: {},
      purchaseTitle: "新增采购单",
      isClient: isClient()
    };
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
  },

  watch: {
    shoppingData(data) {
      let newlist = _.groupBy(data, function (item) {
        return item.CompanyName;
      });
      let datasour = [];
      _.each(newlist, (item, index) => {
        datasour.push({
          list: item,
          Small: this.getSum(item),
          CompanyName: index,
        });
      });
      this.items = datasour;
    },
  },

  methods: {
    getSum(list) {
      let sum = 0;
      _.each(list, (item, index) => {
        sum += item.Small;
      });
      return this.toFloat(sum, 2);
    },
    getDataList() {
      let newlist = _.groupBy(this.getShoppingCart(), function (item) {
        return item.CompanyName;
      });
      let datasour = [];
      _.each(newlist, (item, index) => {
        datasour.push({
          list: item,
          Small: this.getSum(item),
          CompanyName: index,
        });
      });
      this.items = datasour;
    },
    onInit: async function () {
      let _this = this;
      let columns = shppingColumns;
      let operateColumn = _.find(columns, (item) => item.binding === "operate");
      !operateColumn &&
        columns.push({
          name: "operate",
          dataType: 5,
          binding: "operate",
          width: 36,
          header: "操作",
          visible: true,
          isReadOnly: false,
          isFieldsSearch: false,
          isSystem: true,
          // className: 'znlerp-operate znl-text-center',
          renderCell(h, obj) {
            return h(
              "znl-button",
              {
                props: {
                  type: "text",
                },
                on: {
                  click() {
                    // debugger
                    _this.btnDelList(obj.row);
                  },
                },
              },
              "移除"
            );
          },
        });
      this.columns = columns;
      this.getDataList();
    },
    onSearch(params) {},
    btnDelList(row) {
      // 删除供应商
      // debugger
      this.removeShoppingCart(row.JGUID);
      this.$emit("remove-cart-item", row);
    },
    onCancel() {
      this.dialogShow = false;
      this.$emit("close");
    },
    generatePurchase(carItem) {
      // console.log("generatePurchase", carItem);
      this.carItem = carItem;
      let carData = { shoppingCartItem: carItem };
      this.$store.commit("setParamOrder", carData);
      this.purchaseEditVisible = true;
    },
    onPruchaseEditClose() {
      // this.carItem = {};
      this.purchaseEditVisible = false;
    },
    // 移除采购车的项
    onClearCart(val) {
      if (val !== undefined && val.length > 0) {
        _.each(val, (item) => {
          this.removeShoppingCart(item);
        });
        this.$emit("clear-cart-after");
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      document.body.appendChild(this.$el);
    });
  },

  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
};
</script>

<style lang="scss">
.ShoppingCart {
  position: fixed;
  top: 4px;
  height: auto;
  // bottom: 50px;
  right: 6px;
  // width: 465px;
  // background-color: #F6F6F6;
  z-index: 10;
  &.shopping--cart-hide {
    width: 0;
  }
  .overlayer {
    overflow: auto;
    // width: 365px;
  }
  .btnShoppingRow {
    height: 31px;
    .btnShopping {
      width: 115px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      position: absolute;
      color: red;
      top: 3px;
      right: 0px;
      background-color: #f6f6f6;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-top-left-radius: 4px;
      border-right: 1px solid #ccc;
      border-top-right-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      .iconfont {
        position: relative;
      }
      .quantity {
        position: absolute;
        width: 18px;
        height: 14px;
        line-height: 14px;
        background-color: red;
        color: #fff;
        border-radius: 7px;
        top: -4px;
        right: -10px;
      }
      .quantity-value{
        display: inline-block;
        font-size:12px;
        line-height: 14px;
        transform: scale(0.8);
      }
    }
  }

  .btnShoppingOnlyBtn {
    border-radius: 4px;
    border: 1px solid #ccc;
    border-right: none;
  }
  .shopping-box {
    // margin-top: 37px;
    border: 1px solid #ccc;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #f6f6f6;
    padding: 0 8px 8px;
    box-sizing: border-box;
    position: relative;
    left: 0;
    width: 370px;
    // transition: all .5s;
    // margin-right: 20px;
  }
  .shopping-box-hide {
    left: 400px;
  }

  .shopping-row {
    background-color: #e4e4e4;
    padding: 0 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .CompanyName {
    // color: #9900CC;
    font-weight: 600;
  }
  .shopping-row-title {
    line-height: 25px;
    padding: 3px 0;
  }
  .btnOrder {
    float: right;
    font-size: 8px !important;
  }
  .btnDel {
    // padding-left: 3px;
    color: red;
  }
  .none-text {
    text-align: center;
    color: #888;
    line-height: 100px;
  }
  .txt_Small {
    margin-right: 88px;
    float: right;
    top: 0;
    font-size: 13px;
    span {
      color: red;
    }
  }
  .znl-lists {
    .znl-button {
      background-color: transparent;
    }
    // li {
    //   padding: 0px;
    // }
  }
  .znl-lists .znl-list-header.bomai_list_header .bomai_header > li,
  .znl-list-header {
    height: 20px !important;
    line-height: 20px !important;
    .cell {
      font-weight: 500 !important;
    }
  }
  .znl-lists .znl-lists-row.bomai_list_row .body-xul .list-col {
    padding: 0px 5px;
  }
  .znl-lists .znl-lists-row.bomai_list_row .body-xul {
    height: 22px;
    line-height: 22px;
  }
  .znl-btn-text.default .button {
    height: 22px !important;
    line-height: 22px !important;
  }
  .shopping-head {
    height: 32px;
    font-size: 13px;
    font-weight: 600;
    padding-top: 8px;
  }


  // 调整购物车位子
  .isWebShow {
    top: 36px !important
  }
}
</style>
