<template>
  <div>
    <znl-dialog
      title="编辑销售单"
      :visible="editvisible"
      v-loading="logLoading"
      element-loading-text="正在加载页面,请稍等..."
      subhead="(橙色底为必填项)"
      height="90vh"
      width="1000px"
      class="dialog-salesorderedit-box m-border-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      @close="
        (val) => {
          $emit('close', val);
        }
      "
    >
      <znl-gridmodule
        gridtype="action"
        ref="flexGrid"
        :table-handle="false"
        header="编辑销售单"
        class="sales-grid-min-box znl-m-reset-table-par"
        height="100%"
        layout-type="other"
        :has-znl-btns="false"
        :from-height="230"
        element-loading-text="正在保存,请稍等..."
        :from-btns="fromBtns"
        :columns="columns"
        :item-source="itemSource"
        :edit-row-index="editRowIndex"
        :column-colors="columnColors"
        :on-init="onInit"
        :loading="loading"
        @click.native.stop="autocompleteShow = false"
        @edit:cell-ended="onCellEdited"
        :is-multi-rows-check="true"
        :actionFormHeight="267"
        :headerHeight="200"
        :footerHeight="200"
      >
        <!-- 编辑内容 -->
        <div slot="from-module" style="border: 1px solid #d8d8d8">
          <el-row
            class="formContainer"
            type="flex"
            justify="space-around"
            style="
              border-bottom: 1px solid #e6e6e6;
              height: 40px;
              line-height: 40px;
            "
          >
            <el-col :span="12" class="min-preboderleftnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    @change="autocompleteChange"
                    custom-item="my-item-zh"
                    :fetch-suggestions="querySearch"
                    ref="CustomerName"
                    @select="suggestionsSelect"
                    :disabled="fromSalesOrderOpen"
                    placeholder="输入客户简称或拼音字母,并按回车键查询"
                    size="mini"
                    layout="left"
                    :border="true"
                    :is-must-fill="true"
                    title="*客户名称："
                    width="100%"
                    tabindex="1"
                    v-model="SalesOrderAdd.CustomerName"
                    :on-icon-click="SearchCompany"
                    @keyup.enter.native="SearchCompany"
                    type="text"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-edit_btn_ic"
                      @click="SearchCompany"
                    ></i>
                  </znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-preboder-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    ref="BillNo"
                    title-width="70px"
                    form-type="input"
                    :disabled="!IsManual || !SalesOrderAdd.IsAdd"
                    placeholder="默认自动生成 "
                    size="mini"
                    layout="left"
                    :border="true"
                    :is-must-fill="isMustFill('BillNo')"
                    title="单据编号："
                    tabindex="7"
                    width="80%"
                    v-model="SalesOrderAdd.BillNo"
                    :on-icon-click="onBillIconClick"
                    type="text"
                  ></znl-input>

                  <el-checkbox
                    width="20%"
                    size="small"
                    v-model="IsManual"
                    :disabled="!SalesOrderAdd.IsAdd"
                    >手动</el-checkbox
                  >
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    layout="left"
                    ref="SODate"
                    form-type="datepicker"
                    placeholder
                    :readonly="false"
                    :disabled="false"
                    :is-must-fill="true"
                    :border="true"
                    tabindex="8"
                    v-model="SalesOrderAdd.SODate"
                    width="100%"
                    title="*合同日期："
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    ref="SalerName"
                    layout="left"
                    form-type="select"
                    :disabled="false"
                    width="100%"
                    :is-must-fill="true"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="*销售员："
                    :border="true"
                    tabindex="9"
                    :select-options="selectOptionsAllUserURL"
                    @change="onChangeUser"
                    v-model="SalesOrderAdd.SalerID"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-boderleftnone-box">
              <el-row
                class="form_Row_W100"
                type="flex"
                style="margin-bottom: 2px"
              >
                <el-col>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col>
                      <znl-input
                        ref="DeliveryAddress"
                        title-width="70px"
                        :clearable="true"
                        layout="left"
                        form-type="select"
                        width="100%"
                        placeholder="客户地址 / 收货地址"
                        popper-class="popperClass"
                        title="地址："
                        :disabled="false"
                        :border="true"
                        tabindex="2"
                        :filterable="true"
                        :allow-create="true"
                        :is-must-fill="isMustFill('DeliveryAddress')"
                        :select-options="selectOptionsAddress"
                        @blur="changeAddressInputHandler"
                        v-model="SalesOrderAdd.DeliveryAddress"
                      ></znl-input>
                    </el-col>
                  </el-row>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col :span="12">
                      <znl-input
                        ref="Contact"
                        title-width="70px"
                        form-type="input"
                        :disabled="fromSalesOrderOpen"
                        placeholder
                        layout="left"
                        :is-must-fill="isMustFill('Contact')"
                        :border="true"
                        title="联系人："
                        tabindex="3"
                        width="100%"
                        v-model="SalesOrderAdd.Contact"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        ref="Telephone"
                        title-width="70px"
                        :border="true"
                        form-type="input"
                        :disabled="fromSalesOrderOpen"
                        placeholder
                        layout="left"
                        :is-must-fill="isMustFill('Telephone')"
                        title="联系电话："
                        width="100%"
                        tabindex="4"
                        v-model="SalesOrderAdd.Telephone"
                        type="text"
                      ></znl-input>
                    </el-col>
                  </el-row>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col>
                      <znl-input
                        title-width="70px"
                        ref="SalCompany"
                        layout="left"
                        form-type="select"
                        :disabled="false"
                        width="100%"
                        placeholder="请选择"
                        popper-class="popperClass"
                        title="销售公司："
                        :border="true"
                        :clearable="true"
                        tabindex="5"
                        :is-must-fill="isMustFill('SalCompany')"
                        :select-options="selectOptionsUserSubCompany"
                        v-model="SalesOrderAdd.SalCompany"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-boder-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    ref="ReceiptType"
                    title-width="70px"
                    layout="left"
                    form-type="select"
                    :disabled="false"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="收款方式："
                    :border="true"
                    tabindex="10"
                    :clearable="true"
                    :is-must-fill="isMustFill('ReceiptType')"
                    :select-options="selectOptionsPaymentTypeOptions"
                    v-model="SalesOrderAdd.ReceiptType"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    layout="left"
                    ref="CurrencyCode"
                    form-type="select"
                    :disabled="false"
                    width="70%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="*币 种："
                    :border="true"
                    tabindex="13"
                    :is-must-fill="true"
                    @change="CurrencyCodeChange"
                    :select-options="selectOptionsCurrencyCode"
                    v-model="SalesOrderAdd.CurrencyCode"
                  ></znl-input>

                  <znl-input
                    ref="ExchangeRate"
                    title-width="70px"
                    form-type="input"
                    :disabled="this.IsExchangeRateRMB"
                    placeholder
                    size="mini"
                    layout="left"
                    :border="true"
                    tabindex="14"
                    :is-must-fill="isMustFill('ExchangeRate')"
                    title
                    width="28%"
                    v-model="SalesOrderAdd.ExchangeRate"
                    @input="onExchangeChange"
                    type="text"
                  ></znl-input>

                  <znl-input
                    ref="TaxRate"
                    title-width="70px"
                    layout="left"
                    tabindex="21"
                    :disabled="fromSalesOrderOpen"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="税 点："
                    :is-mut-fill="isMustFill('TaxRate')"
                    :border="true"
                    @change="CalcTaxRate"
                    :select-options="selectOptionsTaxRate"
                    v-model="SalesOrderAdd.TaxRate"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    ref="ClearFormDay"
                    :disabled="fromSalesOrderOpen"
                    size="mini"
                    layout="left"
                    :is-must-fill="isMustFill('ClearFormDay')"
                    placeholder
                    title="结算天数："
                    :border="true"
                    tabindex="11"
                    width="100%"
                    v-model="SalesOrderAdd.ClearFormDay"
                    type="text"
                  ></znl-input>

                  <znl-input
                    ref="Amount"
                    title-width="70px"
                    form-type="input"
                    :disabled="disabledInput"
                    :readonly="false"
                    placeholder
                    size="mini"
                    layout="left"
                    title="销售额："
                    tabindex="15"
                    :border="true"
                    width="100%"
                    v-model="SalesOrderAdd.Amount"
                    type="text"
                  ></znl-input>
                  <znl-input
                    ref="PONumber"
                    title-width="70px"
                    form-type="input"
                    :disabled="fromSalesOrderOpen"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="isMustFill('PONumber')"
                    :border="true"
                    tabindex="23"
                    title="销售PO号："
                    width="100%"
                    v-model="SalesOrderAdd.PONumber"
                    type="text"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    ref="ExpectReceiptDate"
                    title-width="70px"
                    form-type="datepicker"
                    :is-must-fill="isMustFill('ExpectReceiptDate')"
                    v-model="SalesOrderAdd.ExpectReceiptDate"
                    width="100%"
                    size="mini"
                    layout="left"
                    :border="true"
                    tabindex="17"
                    title="预收日期："
                  ></znl-input>

                  <znl-input
                    ref="NeedDeliveryDate"
                    title-width="70px"
                    form-type="datepicker"
                    :border="true"
                    tabindex="18"
                    size="mini"
                    layout="left"
                    :is-must-fill="isMustFill('NeedDeliveryDate')"
                    v-model="SalesOrderAdd.NeedDeliveryDate"
                    width="100%"
                    @change="NeedDeliveryDateChange"
                    title="应发日期："
                  ></znl-input>

                  <znl-input
                    ref="SaleGrossProfit"
                    v-show="!noSeeProfit"
                    title-width="70px"
                    layout="left"
                    form-type="input"
                    :disabled="disabledInput"
                    :border="true"
                    tabindex="18"
                    size="mini"
                    :is-must-fill="isMustFill('SaleGrossProfit')"
                    v-model="SalesOrderAdd.SaleGrossProfit"
                    type="text"
                    width="100%"
                    placeholder
                    title="销售毛利："
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-borderleftbottommnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    ref="Remark"
                    title-width="70px"
                    form-type="input"
                    class="m-txt-remark"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="isMustFill('Remark')"
                    title="销售备注："
                    :border="true"
                    tabindex="6"
                    :rows="3"
                    width="100%"
                    v-model="SalesOrderAdd.Remark"
                    type="textarea"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-boderbottomnone-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <label
                    class="znl-input-title"
                    style="
                      height: 30px !important;
                      line-height: 30px !important;
                      width: 80px;
                    "
                    >开票类型：</label
                  >
                  <el-checkbox value="1" v-model="GeneralType" tabindex="19">
                    <label>普通发票</label>
                  </el-checkbox>
                  <el-checkbox
                    value="1"
                    v-model="IncrementType"
                    tabindex="20"
                    style="margin-left: 0 !important"
                  >
                    <label>增值发票</label>
                  </el-checkbox>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    ref="TicketAmount"
                    :disabled="fromSalesOrderOpen"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="isMustFill('TicketAmount')"
                    :border="true"
                    tabindex="22"
                    title="开票额："
                    width="100%"
                    v-model="SalesOrderAdd.TicketAmount"
                    type="text"
                  ></znl-input>

                  <znl-input
                    ref="TicketNo"
                    title-width="70px"
                    form-type="input"
                    :disabled="fromSalesOrderOpen"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="isMustFill('TicketNo')"
                    :border="true"
                    tabindex="24"
                    title="发票号："
                    width="100%"
                    v-model="SalesOrderAdd.TicketNo"
                    type="text"
                  ></znl-input>

                  <el-col v-if="isShopOrdInfoVisible" style="padding-top: 3px">
                    <znl-button
                      style-type="mac"
                      @click="visibleInmore = !visibleInmore"
                      style="float: right"
                    >
                      <span> <i class="iconfont"></i>商城附带信息 </span>
                    </znl-button>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <!-- 表格内容 -->
        <div slot="from-content">
          <el-checkbox
            :disabled="!isMainAccount"
            size="small"
            title="选择库存型号,保存订单后自动锁定数量(需主账号设置)"
            @change="onAotuReserveValue()"
            v-model="isAotuReserve"
          >
            <label title="选择库存型号,保存订单后自动锁定数量(需主账号设置)"
              >自动备货</label
            >
          </el-checkbox>

          <el-checkbox
            size="small"
            @change="onContinuousRecordValue()"
            v-model="isContinueInputMode"
          >
            <label>连续录单</label>
          </el-checkbox>

          <el-checkbox
            size="small"
            @change="onPrintValue()"
            v-model="IsPrint"
            :isShow="hasRes('Print')"
          >
            <label for="IsPrint">保存并打印</label>
          </el-checkbox>

          <znl-button
            style-type="mac"
            :height="22"
            :isShow="hasRes('Print')"
            type="multiple"
          >
            <znl-button-menu @click="onPrint">
              <i class="iconfont icon-print_btn_ic"></i>
              <span>打印</span>
            </znl-button-menu>
            <znl-button-menu
              :width="16"
              tip="打印设置"
              @click="znlPrintVisible = true"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            @click="salesOrderSave(false)"
            :height="22"
            style-type="minor"
            :disabled="isAudit"
            :isShow="hasRes('Print')"
            style
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存</span>
          </znl-button>

          <znl-button
            @click="salesOrderSave(true)"
            :height="22"
            style-type="main"
            :disabled="isAudit"
            :isShow="hasRes('Print')"
            style
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存并关闭</span>
          </znl-button>
        </div>
        <!-- 打印模块 -->
        <znl-print
          :visible.sync="znlPrintVisible"
          :printInfo="printInfo"
          :loading="printLoading"
          :init-param="false"
          :is-get-default-param="printInfo.isGetDefaultParam"
          print-type="ORD_SalesOrder"
          title="销售单打印设置"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
      </znl-gridmodule>
    </znl-dialog>

    <znl-dialog
      title="从Excel粘贴"
      :visible="isBulkAddShow"
      height="300px"
      width="900px"
      class="pasteDataAcquisitionLog"
      :is-footer-show="false"
      @close="isBulkAddShow = false"
    >
      <pasteDataAcquisition
        slot="znl-dialog"
        :columns="setExcelColumns"
        @add-excel-data="addExcelData"
      ></pasteDataAcquisition>
    </znl-dialog>

    <d-company-contact-select
      :visible="companyVisible"
      v-if="companyVisible"
      companyType="1,3"
      title="选择客户"
      :CompanyName="SalesOrderAdd.CustomerName"
      :searchModel="SelectSearchModel"
      @close="
        () => {
          companyVisible = false;
        }
      "
      @update:visible="
        (val) => {
          companyVisible = val;
        }
      "
      @confirm="companyChecked"
    ></d-company-contact-select>

    <dialog-stock-select
      slot="znl-dialog"
      :visible="StkStockCheckVisible"
      v-if="StkStockCheckVisible"
      @confirm="setGridData"
      @close="
        () => {
          StkStockCheckVisible = false;
        }
      "
    ></dialog-stock-select>

    <znl-dialog
      :visible.sync="visibleInmore"
      class="dialog-salesorderedit-box m-border-box sales-grid-min-box edit2-scoped"
      width="460px"
      title="商城附带信息"
      :append-to-body="true"
    >
      <!-- 编辑内容2 -->
      <div style="border: 1px solid #d8d8d8; margin-bottom: 2px">
        <el-row
          class="formContainer m-borderbg-box"
          type="flex"
          justify="space-around"
        >
          <el-col :span="12" class="min-boderleftnone-box">
            <el-row class="form_Row_W100" type="flex">
              <el-col>
                <znl-input
                  title-width="70px"
                  form-type="input"
                  :disabled="true"
                  placeholder="会员账号"
                  v-model="SalesOrderAdd.Account"
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  :border="true"
                  tabindex="26"
                  title="会员账号："
                  width="100%"
                  type="text"
                ></znl-input>
                <znl-input
                  title-width="70px"
                  form-type="input"
                  :disabled="true"
                  v-model="SalesOrderAdd.Commission"
                  placeholder="佣金金额"
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  :border="true"
                  tabindex="24"
                  title="佣金金额："
                  width="100%"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="12" class="min-boder-box">
            <el-row class="form_Row_W100" type="flex">
              <el-col>
                <znl-input
                  title-width="70px"
                  layout="left"
                  form-type="select"
                  :disabled="true"
                  width="100%"
                  placeholder="运费付款方式"
                  v-model="SalesOrderAdd.Payment"
                  popper-class="popperClass"
                  title="运费方式："
                  :border="true"
                  tabindex="28"
                  :is-must-fill="false"
                  :select-options="selectOptionsUserSubCompany"
                ></znl-input>
                <znl-input
                  title-width="70px"
                  form-type="input"
                  :disabled="false"
                  v-model="SalesOrderAdd.Freight"
                  placeholder="运费"
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  :border="true"
                  tabindex="22"
                  title="运费金额："
                  width="100%"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row
          class="formContainer m-borderbg-box"
          type="flex"
          justify="space-around"
        >
          <el-col :span="12" class="min-borderleftbottommnone-box">
            <el-row class="form_Row_W100" type="flex">
              <el-col>
                <!-- <znl-input title-width="70px"
                      :clearable="true"
                      form-type="input"
                      :disabled="!this.fromStoreOrderOpen"
                      placeholder="供货渠道"
                      size="mini"
                      layout="left"
                      :is-must-fill="false"
                      :border="true"
                      tabindex="29"
                      title="供货渠道："
                      width="100%"
                      type="text">
                </znl-input>-->
                <znl-input
                  title-width="70px"
                  layout="left"
                  form-type="select"
                  :disabled="true"
                  width="100%"
                  placeholder="交货方式"
                  v-model="SalesOrderAdd.DeliveryType"
                  title="交货方式："
                  :border="true"
                  tabindex="27"
                  :is-must-fill="false"
                ></znl-input>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="12" class="min-boderbottomnone-box">
            <el-row class="form_Row_W100" type="flex">
              <el-col>
                <znl-input
                  title-width="70px"
                  form-type="input"
                  :disabled="true"
                  placeholder="优惠券金额"
                  v-model="SalesOrderAdd.CouponAmount"
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  :border="true"
                  tabindex="23"
                  title="优惠券："
                  width="100%"
                  type="text"
                ></znl-input>

                <znl-input
                  title-width="70px"
                  form-type="input"
                  :disabled="false"
                  placeholder="手续费"
                  v-model="SalesOrderAdd.Charges"
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  :border="true"
                  tabindex="25"
                  title="手续费："
                  width="100%"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </znl-dialog>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "~components/common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { mixin as common } from "~assets/scripts/methods/common";
import dCompanyContactSelect from "../Crm/DCompanyContactSelect";
import autocomplete from "@c_common/znlGrid/autocomplete";
import DialogStockSelect from "@c_modules/Stk/DialogStockSelect";
import pasteDataAcquisition from "@c_common/znlGrid/commonComponents/pasteDataAcquisition";
import { renderRequiredColumn } from "~assets/scripts/methods/common";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";

const CONFIG = {
  configURL: "SalesOrderLine/GetConfig",
  saveConfigURL: "SalesOrderrAdd/SaveConfig",
  resetConfigURL: "SalesOrderAdd/ResetConfig",
  SaleOrferSaveURL: "SalesOrderAdd/ModifyOrder",
  PurchaseOrferSearchById: "SalesOrderAdd/Search",
  bomLineGotoSales: "BomDetailLine/BomLineGotoSales",
  SearchByBOMGUIDS: "SalesOrderAdd/SearchByBOMGUIDS",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  GetPrintTemplatesURL: "PrintTemplate/Search",
  configJsonURL: "SalesOrder/GetConfig",
  GetPrice: "SalesOrderAdd/GetPrice", // 获取上次的价格
  GetCompanySetupUrl: "CompanyParamSetup/GetValue", //获取公司配置
};

export default {
  name: "SalesOrderAdd",
  mixins: [
    znlMethodsMixin,
    moveToMixin,
    getCommonDataMixin,
    renderRequiredColumn,
    common,
  ],
  config: CONFIG,
  components: {
    dCompanyContactSelect,
    autocomplete,
    pasteDataAcquisition,
    DialogStockSelect,
    ZnlPrint,
  },
  data() {
    return {
      isRead: false, // 控制输入框是否只读
      selectOptionsAddress: [],
      autocompleteShow: false,
      autocompleteSearchData: "",
      logLoading: true,
      isContinueInputMode: false, // 连续录单模式
      isAotuReserve: false, // 自动备货
      isBulkAddShow: false,
      StkStockCheckVisible: false,
      startTime: 0,
      loading: false,
      currentUserName: this.$store.state.accountInfo.UserName, // 当前登录名称
      companyVisible: false,
      paramTax: "",
      SalesOrderAdd: {
        Account: "",
        SalCompany: "", // 销售公司
        BillNo: "",
        SODate: new Date().toLocaleDateString(),
        PONumber: "",
        DeliveryAddress: "", // 收货地址
        SalerID: this.$store.state.accountInfo.UserID,
        SalerName: this.$store.state.accountInfo.UserName,
        CustomerName: "",
        CustomerGUID: "",
        Contact: "",
        Telephone: "",
        Phone: "",
        ReceiptType: "", // 收款方式（用枚举）
        ClearFormDay: "",
        CurrencyCode: "RMB",
        ExchangeRate: "1",
        TaxRate: null,
        Amount: "",
        ExpectReceiptDate: "",
        IsInvoiced: false,
        InvoiceType: "",
        TicketAmount: "",
        TicketNo: "",
        FreightClearForm: "",
        NeedDeliveryDate: "",
        Remark: "",
        StkInStatus: 0,
        StkOutStatus: 0,
        ReturnStatus: 0,
        DiscountAmount: "",
        IsAdd: true,
        LineList: [],
        GrossProfit: 0,
        SaleGrossProfit: 0,
        PDID: "",
        SOGuid: null,
        SOID: 0,
        SaleOrderStatus: null,
        CBGUID: "", // 收款账号
        Freight: null,
        CouponAmount: null,
        Commission: null,
        Charges: null,
        DeliveryType: null,
        Payment: null,
        IsAotuReserve: null,
      },
      DeliveryTypeOptions: [
        {
          key: 1,
          value: "快递寄送",
        },
        {
          key: 2,
          value: "自提",
        },
      ],
      PaymentOptions: [
        {
          key: 1,
          value: "运费寄付",
        },
        {
          key: 2,
          value: "运费到付",
        },
      ],
      ChannelOptions: [
        {
          key: 1,
          value: "自营、渠道商、云库存、代理库存",
        },
      ],
      IsTakeOutPrint: false, // 是否带出上次价格
      isMatchByPackaging: false, // 自动采用上一次出库价格包涵匹配封装
      IsCustomerName: true, // 公司名称是否必填
      paramQtyType: "",
      paramQtyUnit: "",
      ParamJosn: [],
      ParamJosnLine: [],
      IsPrint: true, // 保存后继续打印
      disabledInput: true, // 禁用采购单
      IsManual: false, // 手动
      fromSalesOrderOpen: false,
      fromStoreOrderOpen: false,
      toptions: [], // 测试CODE
      // 绑定列表中的下拉框
      InvoiceTypeOptions: [],
      pickerOptions0: {},
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      setExcelColumns: [],
      isAudit: false,
      resourceList: [],
      role: "1",
      inTypes: "",
      isAllow: true,
      isPopup: false,
      IsExchangeRateRMB: true,
      IncrementType: false,
      GeneralType: false,
      SelectSearchModel: {
        CompanyName: "",
      },
      visibleInmore: false,
      fromBtns: [
        {
          name: "新增一行",
          iconName: "icon-add_btn_ic",
          click: () => {
            this.$refs.flexGrid.addRow(true);
          },
        },
        {
          name: "删除选择行",
          iconName: "icon-delete_btn_ic",
          click: () => {
            _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
              _.each(this.itemSource, (item, i) => {
                if (_.isEqual(item, data)) {
                  this.itemSource.splice(i, 1);
                  return;
                }
              });
            });
            // let Amount = 0
            // _.each(this.itemSource, (item, i) => {
            //   if(item.Amount)
            //     Amount += Number(item.Amount)
            // })
            // this.SalesOrderAdd.Amount = Amount
            this.CalcOrderAmoutAndGrossProfit();
          },
        },
        {
          name: "导入数据",
          iconName: "icon-add_btn_ic",
          isShow: true,
          click: () => {
            this.isBulkAddShow = !this.isBulkAddShow;
            let col = _.filter(this.columns, (item) => {
              return item.isReadOnly === false;
            });
            this.setExcelColumns = col;
          },
        },
        {
          name: "我的库存",
          iconName: "icon-save_btn_ic",
          isShow: true,
          click: () => {
            this.StkStockCheckVisible = true;
          },
        },
      ],
      GCode: "SalesOrder",
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "ORD_SalesOrder", // 打印模板类型
      }, // end 打印模块参数
      isShopOrdInfoVisible: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount, // false 为非主账号
      noSeeProfit: false,
      mustFillFieldList: [],
      profitMarginMode: 1, // 毛利率配置。 1： 销售毛利率（销售价-采购价）/销售价， 2：采购毛利率：(销售价-采购价)/采购价
      //公司配置
      CompanySetup: {
        EnableNotUpperCase: false, //单据的型号、品牌﹑封装、年份默认为非大写格式
      },
    };
  },
  props: {
    editType: String,
    editvisible: {
      type: Boolean,
      defaule: false,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: String,
      default: () => {
        return "99%";
      },
    },
    SOGuid: String,
    autoAddNewRow: {
      // 是否在明细自动增加一行
      type: Boolean,
      default: () => {
        return true;
      },
    },
    BDLineGuids: Array, // 库存对象GUIDS
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage("salesOrderLine", CONFIG.configURL);
      let configs = await this.isSaveStorage(
        "salesOrder",
        CONFIG.configJsonURL
      );

      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      this.resourceList = config.ResourceList.map((item) => item.Code);
      // 获取参数配置
      this.ParamJosn = configs.ParamJosn;
      // 获取订单详情参数配置
      this.ParamJosnLine = config.ParamJosn;
      this.paramConfiguration();
      this.profitMarginMode = await this.getCompanySetupValue(
        "ProfitMarginMode"
      );

      let invTypeList = await this.selectStorageType;
      // 库存类型
      let stkTypeColumn = _.find(
        columns,
        (column) => column.name === "InvType"
      );
      stkTypeColumn &&
        _.extend(stkTypeColumn, {
          dataMap: invTypeList,
          showDropDown: true,
        });
      // 品质
      let qualityDropDown = await this.selectOptionsQuality;
      qualityDropDown &&
        _.extend(
          _.find(columns, function (column) {
            return column.binding === "Quality";
          }),
          { dataMap: qualityDropDown, showDropDown: true }
        );
      // 单位
      let qtyUnitDropDown = await this.selectOptionsQtyUnit;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Unit";
        }),
        { dataMap: qtyUnitDropDown, showDropDown: true }
      );
      if (!this.isMainAccount) {
        this.noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");
        if (this.noSeeProfit === true) {
          // 利润
          let GrossProfitColumn = _.find(
            columns,
            (column) => column.name === "GrossProfit"
          );
          // 利润率
          let ProfitMarginsColumn = _.find(
            columns,
            (column) => column.name === "GrossProfitRate"
          );
          // 采购价
          let buyPriceColumn = _.find(
            columns,
            (column) => column.name === "CostPrice"
          );
          GrossProfitColumn &&
            _.extend(GrossProfitColumn, { isReadOnly: true, hasRole: false });
          ProfitMarginsColumn &&
            _.extend(ProfitMarginsColumn, { isReadOnly: true, hasRole: false });
          buyPriceColumn &&
            _.extend(buyPriceColumn, { isReadOnly: true, hasRole: false });
        }
      }
      columns = this.renderRequiredColumn(columns);
      this.defaultConfig = config;
      this.columns = columns;
      this.role = config.Role;
      this.fromSalesOrderOpen = false;
      this.pageSize = config.PageSize;

      if (this.$store.state.paramOrder && this.$store.state.paramOrder.SOGuid) {
        let soguid = this.$store.state.paramOrder.SOGuid;
        let order = {};
        this.$store.commit("setParamOrder", order);
        this.LoadEditData(soguid);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.bomInfo
      ) {
        let info = this.$store.state.paramOrder.bomInfo;
        // this.LoadListByMtlGuids(info, false)
        await this.LoadBomLine(info, false);
        this.$store.commit("setParamOrder", {});
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.BOMGUIDS
      ) {
        let info = this.$store.state.paramOrder.BOMGUIDS;
        this.LoadListByBOMGUIDS(info, false);
        this.$store.commit("setParamOrder", {});
      }
      this.$nextTick(() => {
        this.logLoading = false;
        // 重置table height

        let time = setTimeout(() => {
          this.resetTableHeight({
            dom: ".dialog-salesorderedit-box .znl-dialog__body",
            parentDom: ".dialog-salesorderedit-box .znl-dialog",
            brother: [".znl-m-reset-table-par .znl-dialog__header"],
            surplus: 20,
            heightSet: true,
          });

          this.resetTableHeight({
            dom: ".znl-m-reset-table-par .el-table",
            parentDom: ".dialog-salesorderedit-box .znl-dialog",
            brother: [
              ".znl-m-reset-table-par .znl-from-module",
              ".znl-m-reset-table-par .znl-dialog__header",
            ],
            surplus: 20,
            heightSet: true,
          });

          clearTimeout(time);
        });
      });
      await this.initCompanySetup();
    },
    //初始化公司配置
    async initCompanySetup() {
      this.$post(
        CONFIG.GetCompanySetupUrl,
        { SCode: "EnableNotUpperCase" },
        (data, logic) => {
          if (logic.code === 200) {
            data = data.toString().toUpperCase();
            this.CompanySetup.EnableNotUpperCase =
              data === "1" || data === "Y" || data === "YES" || data === "TRUE";
          }
        }
      );
    },
    setGridData(list) {
      let rowID = 0;
      _.each(list, (item) => {
        let invQty = item.UsableQty;
        item.Qty = invQty;
        item.CostPrice = item.BuyPrice;
        let price = item.SalesPrice;
        if (!this.hasValue(item.SalesPrice)) {
          price = item.Price;
        }
        item.RowGuid = rowID++;
        item.InvType = !this.hasValue(item.InvType)
          ? this.paramQtyType
          : item.InvType; // 库存类型
        item.Unit = !this.hasValue(item.Unit) ? this.paramQtyUnit : item.Unit; // 单位
        item.Price = this.ConvertNumber(price);

        item.PriceInTax = this.CalcPriceInTax(this.ConvertNumber(price)); // 含税价
        item.Amount = this.CalcAmout(
          this.ConvertNumber(invQty),
          this.ConvertNumber(price)
        );
        item.PurchaseAmount = this.CalcAmout(invQty, item.BuyPrice);
      });
      if (this.itemSource && this.itemSource.length > 0) {
        let info = this.itemSource[this.itemSource.length - 1];
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop();
        }
      }
      this.itemSource = this.itemSource.concat(list);
      this.CalcOrderAmoutAndGrossProfit(false);
    },
    onResetPage() {
      let info = {
        Account: "",
        SalCompany: "", // 销售公司
        BillNo: "",
        SODate: new Date().toLocaleDateString(),
        PONumber: "",
        DeliveryAddress: "", // 收货地址
        SalerID: this.$store.state.accountInfo.UserID,
        SalerName: this.$store.state.accountInfo.UserName,
        CustomerName: "",
        CustomerGUID: "",
        Contact: "",
        Telephone: "",
        Phone: "",
        ReceiptType: "", // 收款方式（用枚举）
        ClearFormDay: "",
        CurrencyCode: "RMB",
        ExchangeRate: "1",
        TaxRate: null,
        Amount: "",
        ExpectReceiptDate: "",
        IsInvoiced: false,
        InvoiceType: "",
        TicketAmount: "",
        TicketNo: "",
        FreightClearForm: "",
        NeedDeliveryDate: "",
        Remark: "",
        StkInStatus: 0,
        StkOutStatus: 0,
        ReturnStatus: 0,
        DiscountAmount: "",
        IsAdd: true,
        LineList: [],
        GrossProfit: 0,
        SaleGrossProfit: 0,
        PDID: "",
        SOGuid: null,
        SOID: 0,
        SaleOrderStatus: null,
        CBGUID: "", // 收款账号
        Freight: null,
        CouponAmount: null,
        Commission: null,
        Charges: null,
        DeliveryType: null,
        Payment: null,
      };
      this.SalesOrderAdd = _.extend(this.SalesOrderAdd, info);
      this.itemSource = [];
    },
    onChangeUser(val) {
      if (val) {
        this.SalesOrderAdd.SalerName = val.value;
      }
    },

    hasRes(code) {
      return _.some(this.resourceList, (page) => page.Code === code);
    },
    paramConfiguration() {
      // 参数
      let param = null;
      // 单据保存后立即打打印 0是 1否
      param = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(param) &&
        this.hasValue(param.ParamValue) &&
        param.ParamValue === "1"
      ) {
        this.IsPrint = true;
      } else {
        this.IsPrint = false;
      }
      // 连续录单模式
      param = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(param) &&
        this.hasValue(param.ParamValue) &&
        param.ParamValue === "1"
      ) {
        this.isContinueInputMode = true;
      } else {
        this.isContinueInputMode = false;
      }
      // 自动备货
      param = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsAotuReserve") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(param) &&
        this.hasValue(param.ParamValue) &&
        param.ParamValue === "1"
      ) {
        this.isAotuReserve = true;
      } else {
        this.isAotuReserve = false;
      }
      // 默认单位
      param = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyUnit") {
          return paramjosn.ParamValue;
        }
      });
      if (this.hasValue(param) && this.hasValue(param.ParamValue)) {
        this.paramQtyUnit = param.ParamValue;
      }
      let defaultUnit = this.getCompanySetupValue("DefaultUnit");
      if (defaultUnit) {
        this.paramQtyUnit = defaultUnit;
      }
      // 默认库存类型
      param = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyType") {
          return paramjosn.ParamValue;
        }
      });
      if (this.hasValue(param) && this.hasValue(param.ParamValue)) {
        this.paramQtyType = param.ParamValue;
      }
      // 默认币种
      param = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "CurrencyCode") {
          return paramjosn.ParamValue;
        }
      });
      if (this.hasValue(param) && this.hasValue(param.ParamValue)) {
        this.SalesOrderAdd.CurrencyCode = param.ParamValue;
      }
      let defaultCurrencyCode = this.getCompanySetupValue(
        "DefaultSalesCurrencyCode"
      );
      if (defaultCurrencyCode) {
        this.SalesOrderAdd.CurrencyCode = defaultCurrencyCode;
        let exchangeObj = _.find(
          this.selectOptionsCurrencyCode,
          (m) => m.key === defaultCurrencyCode
        );
        if (exchangeObj && exchangeObj.ExchangeRate) {
          this.SalesOrderAdd.ExchangeRate = exchangeObj.ExchangeRate;
        }
      }

      // 默认税率
      param = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "TaxRate") {
          return paramjosn.ParamValue;
        }
      });
      if (this.hasValue(param) && this.hasValue(param.ParamValue)) {
        this.paramTax = param.ParamValue;
        this.SalesOrderAdd.TaxRate = param.ParamValue;
      }

      // 如果系统设置了默认税点，则覆盖
      let defaultTaxRate = this.getCompanySetupValue("DefaultTaxRate");
      if (defaultTaxRate) {
        this.SalesOrderAdd.TaxRate = defaultTaxRate;
      }

      // 是否带出上次价格(匹配:客户名称和型号)
      param = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsTakeOutPrint") {
          return paramjosn.ParamValue;
        }
      });
      // debugger
      // 是否自动采用上一次出库价格包涵匹配封装
      this.isMatchByPackaging = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "isMatchByPackaging") {
          return paramjosn.ParamValue ? paramjosn.ParamValue : 0;
        }
      });
      if (
        this.hasValue(param) &&
        this.hasValue(param.ParamValue) &&
        param.ParamValue === "0"
      ) {
        this.IsTakeOutPrint = false;
      } else {
        this.IsTakeOutPrint = true;
      }
      // 打印单据模板
      let paramPrintTemplate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTemplate") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTemplate) &&
        this.hasValue(paramPrintTemplate.ParamValue)
      ) {
        this.printInfo.template = paramPrintTemplate.ParamValue;
      }
      // 打印单据模板的抬头
      let paramPrintTitle = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTitle") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTitle) &&
        this.hasValue(paramPrintTitle.ParamValue)
      ) {
        this.printInfo.title = paramPrintTitle.ParamValue;
      }
      // 必填字段
      this.loadMustFillFields();
    },
    loadMustFillFields() {
      // 必填字段
      let mustFillFieldList = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "MustFillField") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(mustFillFieldList) &&
        this.hasValue(mustFillFieldList.ParamValue)
      ) {
        // console.log(mustFillFieldList)
        this.mustFillFieldList = mustFillFieldList.ParamValue.split(",");
      }
    },
    isMustFill(fieldName) {
      // console.log(fieldName)
      if (this.mustFillFieldList && this.mustFillFieldList.length > 0) {
        return this.mustFillFieldList.indexOf(fieldName) > -1;
      }
      return false;
    },
    onPrint() {
      var tableName = this.printInfo.template; // 模板ID
      var SOGuid = this.SalesOrderAdd.SOGuid; // 销售单ID
      if (!this.hasValue(tableName)) {
        this.$message({ message: "先设置打印模板后再操作!", type: "error" });
        return false;
      } else if (SOGuid === "" || SOGuid === null || SOGuid === undefined) {
        this.$message({ message: "先保存后才能打印!", type: "error" });
        return false;
      } else {
        let printParm = {
          TableName: tableName,
          Guid: SOGuid,
          SubCompanyId: this.printInfo.title,
        };
        this.printSalesOrder(printParm, () => {});
        return true;
      }
    },

    onBindData(pageIndex = 1) {
      this.itemSource = this.SalesOrderAdd.LineList;
      this.$emit("page-loading", false);
    },

    querySearch(queryString, cb) {
      if (new Date() - this.startTime > 1000) {
        this.$post(
          "CompanyContacts/SearchCompanyContacts",
          {
            FieldWhereString: JSON.stringify({
              CompanyName: queryString,
            }),
            PageIndex: 1,
            PageSize: 20,
            CompanyTypes: "1,3",
          },
          (data) => {
            this.restaurants = data.ResultList;
            let restaurants = this.restaurants;
            let results = queryString
              ? restaurants.filter(this.createFilter(queryString))
              : restaurants;
            // 调用 callback 返回建议列表的数据
            _.each(results, (item) => {
              item.value = item.CompanyName;
            });
            cb(results);
            _.delay(() => {
              this.$refs.znlAutocomplete.$el
                .querySelector(".el-input__inner")
                .focus();
            }, 100);
          }
        );
      }
      this.startTime = new Date();
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.CompanyName.indexOf(queryString.toLowerCase()) === 0;
      };
    },
    suggestionsSelect(item) {
      this.companyChecked([item]);
    },
    checkTheMustFillItems() {
      let emptyField = _.filter(this.mustFillFieldList, (field) => {
        return !this.hasValue(this.SalesOrderAdd[field]);
      });
      let emptyFieldStr = [];
      if (emptyField.length > 0) {
        let fieldObj = {};
        _.each(emptyField, (field) => {
          fieldObj = this.$refs[field];
          if (fieldObj && fieldObj.title) {
            emptyFieldStr.push(
              fieldObj.title.substring(0, fieldObj.title.length - 1)
            );
          }
        });
        if (emptyFieldStr && emptyFieldStr.length > 0) {
          this.$message({
            message: emptyFieldStr.join(",") + "不能为空",
            type: "warning",
          });
        }
        return false;
      } else {
        return true;
      }
    },
    checkData() {
      let ret = true;
      if (this.$refs.flexGrid.hasErrForEdit > 0) {
        ret = false;
        this.$message({
          message: "请修改填写错误的单元格内容后再保存",
          type: "error",
        });
      } else if (!this.hasValue(this.SalesOrderAdd.CustomerName)) {
        ret = false;
        this.$refs.CustomerName.handleFocus();
        this.$message({ message: "客户名称不能为空", type: "warning" });
      } else if (!this.hasValue(this.SalesOrderAdd.SODate)) {
        ret = false;
        this.$refs.SODate.handleFocus();
        this.$message({ message: "请选择合同日期", type: "warning" });
      } else if (
        this.SalesOrderAdd.SalerID <= 0 ||
        !this.hasValue(this.SalesOrderAdd.SalerID)
      ) {
        ret = false;
        this.$refs.SalerName.handleFocus();
        this.$message({ message: "请选择销售员", type: "warning" });
      } else if (
        this.SalesOrderAdd.CurrencyCode === undefined ||
        this.SalesOrderAdd.CurrencyCode === "" ||
        this.SalesOrderAdd.CurrencyCode === 0
      ) {
        ret = false;
        this.$refs.CurrencyCode.handleFocus();
        this.$message({ message: "请选择币种", type: "warning" });
      } else {
        //删除为空的最后一行
        if (this.itemSource.length > 1) {
          var lastCol = this.itemSource.length - 1;
          var lastRow = this.itemSource[lastCol];
          if (
            !this.hasValue(lastRow["Model"]) &&
            !this.hasValue(lastRow["Qty"]) &&
            !this.hasValue(lastRow["Price"])
          )
            this.itemSource = this.itemSource.splice(0, lastCol);
        }
        let rows = this.itemSource;
        if (rows.length > 0) {
          let rowId = 0;
          let messageStr = "";
          _.some(rows, (item) => {
            rowId = rowId + 1;
            if (!this.hasValue(item["Model"])) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 型号不能为空";
            } else if (item["Model"].length > 200) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 型号长度不能超过200个字符";
            } else if (!this.hasValue(item["Qty"])) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 数量必须为数值型且不能为空";
            } else if (!this.hasValue(item["Price"])) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 未税价必须为数值型且不能为空";
            } else if (!this.hasValue(item["PriceInTax"])) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 含税价必须为数值型且不能为空";
            } else if (
              this.hasValue(item["CustomerPartNo"]) &&
              item["CustomerPartNo"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 客户料号不能超过50个字符";
            } else if (
              this.hasValue(item["ProductCategory"]) &&
              item["ProductCategory"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 产品类型不能超过50个字符";
            } else if (
              this.hasValue(item["Brand"]) &&
              item["Brand"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 品牌长度不能超过50个字符";
            } else if (
              this.hasValue(item["Packaging"]) &&
              item["Packaging"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 封装长度不能超过50个字符";
            } else if (
              this.hasValue(item["MakeYear"]) &&
              item["MakeYear"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 年份长度不能超过50个字符";
            } else if (
              this.hasValue(item["DeliveryDate"]) &&
              item["DeliveryDate"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 货期长度不能超过50个字符";
            } else if (this.hasValue(item["MPQ"]) && item["MPQ"].length > 50) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 包装长度不能超过50个字符";
            } else if (
              this.hasValue(item["Demands"]) &&
              item["Demands"].length > 50
            ) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 采购要求长度不能超过50个字符";
            } else if (
              this.hasValue(item["Remark"]) &&
              item["Remark"].length > 200
            ) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 备注长度不能超过200个字符";
            }
          });
          if (messageStr.length > 0) {
            ret = false;
            this.$message({ message: messageStr, type: "warning" });
          }
        } else {
          ret = false;
          this.$message({ message: "请添加单据详情", type: "warning" });
        }
        this.SalesOrderAdd.LineList = rows;
      }
      if (this.SalesOrderAdd.LineList.length > 0) {
        if (this.SalesOrderAdd.FreightClearForm) {
          if (isNaN(this.SalesOrderAdd.FreightClearForm)) {
            var FreightClearFormIndex = _.indexOf(
              this.FreightClearFormOptions,
              _.find(this.FreightClearFormOptions, (i) => {
                return i["value"] === this.SalesOrderAdd.FreightClearForm;
              })
            );
            this.SalesOrderAdd.FreightClearForm =
              this.FreightClearFormOptions[FreightClearFormIndex].key;
          }
        }
      }
      if (this.hasValue(this.isAotuReserve)) {
        this.SalesOrderAdd.IsAotuReserve = this.isAotuReserve;
      }
      if (!this.checkTheMustFillItems()) {
        ret = false;
      }
      return ret;
    },
    checkLength() {
      let ret = true;
      if (
        this.hasValue(this.SalesOrderAdd.CustomerName) &&
        this.SalesOrderAdd.CustomerName.length > 100
      ) {
        ret = false;
        this.$refs.CustomerName.handleFocus();
        this.$message({
          message: "客户名称长度不能超过100个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.SalesOrderAdd.Contact) &&
        this.SalesOrderAdd.Contact.length > 50
      ) {
        ret = false;
        this.$refs.Contact.handleFocus();
        this.$message({
          message: "联系人长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.SalesOrderAdd.Telephone) &&
        this.SalesOrderAdd.Telephone.length > 50
      ) {
        ret = false;
        this.$refs.Telephone.handleFocus();
        this.$message({
          message: "联系电话长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.SalesOrderAdd.DeliveryAddress) &&
        this.SalesOrderAdd.DeliveryAddress.length > 200
      ) {
        ret = false;
        this.$refs.DeliveryAddress.handleFocus();
        this.$message({
          message: "地址长度不能超过200个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.SalesOrderAdd.Remark) &&
        this.SalesOrderAdd.Remark.length > 1000
      ) {
        ret = false;
        this.$message({
          message: "销售备注不能超过1000个字符",
          type: "warning",
        });
      }
      return ret;
    },

    save(isClose) {
      this.loading = true;
      var vm = this;

      if (this.GeneralType === true && this.IncrementType === false)
        this.SalesOrderAdd.InvoiceType = 1;
      else if (this.GeneralType === false && this.IncrementType === true)
        this.SalesOrderAdd.InvoiceType = 2;
      else this.SalesOrderAdd.InvoiceType = null;

      return this.$post(
        CONFIG.SaleOrferSaveURL,
        this.SalesOrderAdd,
        (data, logic) => {
          this.loading = false;
          if (logic.code === 200) {
            this.$message({ message: "保存成功", type: "success" });
            this.$emit("save-success", true);
            this.SalesOrderAdd = {};
            this.SalesOrderAdd = data;
            // this.itemSource = data.LineList
            this.SalesOrderAdd.IsAdd = false;
            if (vm.IsPrint) {
              vm.onPrint();
            }
            if (isClose) {
              this.$emit("close");
            } else if (this.isContinueInputMode) {
              this.onResetPage(); // 重置
            }
          } else {
            let msg = logic.msg || "保存失败";
            this.showError(msg);
          }
        }
      )
        .catch((e) => {
          console.log("e:", e);
        })
        .finally(() => {
          vm.loading = false;
        });
    },
    salesOrderSave(isClose) {
      // 保存
      if (this.checkData() && this.checkLength()) {
        this.save(isClose);
      }
    },
    // 计算金额和毛利润
    CalcOrderAmoutAndGrossProfit(isEdit) {
      this.CalcOrderLineAmountAndGrossProfit();
      let orderAmount = 0;
      let orderGrossProfit = 0;
      _.each(this.itemSource, (item) => {
        orderAmount += this.toFloat(item.Amount, 2);
        orderGrossProfit += this.toFloat(item.GrossProfit, 2);
      });
      this.SalesOrderAdd.Amount = this.toFloat(orderAmount, 2);
      this.SalesOrderAdd.SaleGrossProfit = this.toFloat(orderGrossProfit, 2);
    },
    CalcOrderLineAmountAndGrossProfit() {
      let list = [];
      // let that = this
      this.itemSource.forEach((row) => {
        if (
          _.isUndefined(row.CostPrice) &&
          _.isUndefined(row.Price) &&
          _.isUndefined(row.Qty)
        ) {
          list.push(row);
          return false;
        }
        let costprice = this.ConvertNumber(row.CostPrice);
        let salesprice = this.ConvertNumber(row.Price);
        let qty = this.ConvertNumber(row.Qty);
        let exchangeRate = this.SalesOrderAdd.ExchangeRate; //汇率
        //row.GrossProfit = this.toFloat((salesprice - costprice) * qty, 2);
        row.GrossProfit = this.toFloat(
          (salesprice * exchangeRate - costprice) * qty,
          2
        );
        // this.profitMarginMode = '1'
        // console.log('this.profitMarginMode', this.profitMarginMode)
        if (this.profitMarginMode === "2") {
          console.log("按采购毛利率计算");
          // 采购毛利率
          if (salesprice && salesprice !== 0 && costprice && costprice !== 0) {
            row.GrossProfitRate =
              this.toFloat(
                (salesprice * exchangeRate - costprice) / costprice,
                2
              ) *
                100 +
              "%";
          } else {
            row.GrossProfitRate = "";
          }
        } else {
          if (salesprice && salesprice !== 0) {
            costprice = costprice || 0;
            row.GrossProfitRate =
              this.toFloat(
                ((salesprice * exchangeRate - costprice) /
                  (salesprice * exchangeRate)) *
                  100,
                2
              ) + "%";
          } else {
            row.GrossProfitRate = "";
          }
        }
        // if (salesprice !== 0 && costprice && costprice !== 0) {
        //   row.GrossProfitRate =
        //     //this.toFloat(((salesprice - costprice) / salesprice) * 100, 2) + "%";
        //     this.toFloat(
        //       ((salesprice * exchangeRate - costprice) / costprice) * 100,
        //       2
        //     ) + "%";
        // }
        list.push(row);
      });
      this.itemSource = list;
    },
    // 编辑单据
    LoadEditData: async function (guid) {
      let orders = await this.$post(CONFIG.PurchaseOrferSearchById, {
        SOGuid: guid,
      });
      _.each(orders.LineList, (item) => {
        if (item.NeedDeliveryDate === undefined) {
          item.NeedDeliveryDate = "";
        }
      });
      this.SalesOrderAdd = orders;
      if (this.SalesOrderAdd.InvoiceType === 2) {
        this.GeneralType = false;
        this.IncrementType = true;
      } else if (this.SalesOrderAdd.InvoiceType === 1) {
        this.GeneralType = true;
        this.IncrementType = false;
      } else {
        this.GeneralType = false;
        this.IncrementType = false;
      }
      this.getAddress(this.SalesOrderAdd);
      this.isPopup = false;
      var i = 0;
      this.$nextTick(function () {
        !this.hasValue(this.SalesOrderAdd.LineList[i].Unit)
          ? (this.SalesOrderAdd.LineList[i].Unit = this.paramQtyUnit)
          : "";
        !this.hasValue(this.SalesOrderAdd.LineList[i].InvType)
          ? (this.SalesOrderAdd.LineList[i].InvType = this.paramQtyType)
          : "";
        this.onBindData();
        this.CalcOrderAmoutAndGrossProfit(true);
        this.isAudit = orders.IsAudited;
        this.SalesOrderAdd.IsAdd = false;
        this.SalesOrderAdd.SOGuid = guid;
        i++;
      });
      this.isAllow = false; // 编辑禁用选择
      this.isRead = true;
      this.isShopOrdInfoVisible =
        this.SalesOrderAdd.OrderSource === "shop" &&
        this.SalesOrderAdd.SourceID;
      if (this.isShopOrdInfoVisible) {
        // 商城明细字段
        let modelPriceField = _.find(this.columns, function (item) {
          return item.binding === "ModelPrice";
        });
        let channelField = _.find(this.columns, function (item) {
          return item.binding === "Channel";
        });

        if (modelPriceField) {
          _.extend(modelPriceField, { visible: true, editable: false });
        }
        if (channelField) {
          _.extend(channelField, { visible: true, editable: false });
        }
      }
    },

    // 币种改变选项事件
    CurrencyCodeChange(val) {
      let itemVal = val ? val.key : this.SalesOrderAdd.CurrencyCode; // 选中的值
      if (!this.hasValue(itemVal)) {
        itemVal = "RMB";
      }
      // console.log('itemVal', itemVal)
      if (itemVal === "RMB") {
        this.IsExchangeRateRMB = true;
      } else {
        this.IsExchangeRateRMB = false;
      }
      var info = _.find(this.selectOptionsCurrencyCode, function (item) {
        return item.key === itemVal;
      });
      // console.log('info', info)
      this.SalesOrderAdd.ExchangeRate = info.ExchangeRate;
    },

    onExchangeChange() {
      this.CalcOrderAmoutAndGrossProfit();
      // this.CalcOrderLineAmountAndGrossProfit();
    },

    // 应发日期同步到详情
    NeedDeliveryDateChange(val) {
      function formatTen(num) {
        return num > 9 ? num + "" : "0" + num;
      }
      if (!val) {
        return;
      }
      let d = new Date(val);
      let NeedDeliveryDate =
        d.getFullYear() + "-" + formatTen(d.getMonth() + 1) + "-" + d.getDate();
      _.each(this.itemSource, (item, i) => {
        this.itemSource[i].NeedDeliveryDate = NeedDeliveryDate;
      });
      this.SalesOrderAdd.LineList = this.itemSource;
    },
    getColumn(name) {
      let list = [{ name: "_Checked" }];
      let columns = this.columns;
      _.each(columns, (item) => {
        if (item.visible) {
          list.push(item);
        }
      });
      for (let i = 0; i < list.length; i++) {
        if (name === list[i].name) {
          return {
            col: columns[i],
            index: i,
          };
        }
      }
    },

    // 根据税率计算小计金额和含税单价
    CalcTaxRate(val) {
      val
        ? (this.SalesOrderAdd.TaxRate = val.key)
        : (this.SalesOrderAdd.TaxRate = null);
      if (this.itemSource.length > 0) {
        const source = this.itemSource;
        for (let i = 0; i < source.length; i++) {
          // 计算未税价和含税价和金额
          if (this.hasValue(source[i].Price)) {
            // 计算含税
            let _priceIntax = this.CalcPriceInTax(source[i].Price);
            source[i].PriceInTax = _priceIntax;
          } else if (this.hasValue(source[i].PriceInTax)) {
            // 含税算未税
            let _price = this.CalcPrice(source[i].PriceInTax);
            source[i].Price = _price;
          }
          source[i].Amount = this.CalcAmout(
            source[i].Qty,
            source[i].PriceInTax
          ).toFixed(2);
        }
        this.itemSource = JSON.parse(JSON.stringify(source));
        this.CalcOrderAmoutAndGrossProfit(false);
      }
    },
    SearchCompany() {
      this.InTypes = "1,3"; // 客户和供应商客户
      this.companyVisible = true;
      this.SelectSearchModel.CompanyName = this.SalesOrderAdd.CustomerName;
    },

    // 多页签 双击事件回调
    async ondblclick(row, typeName) {
      // console.log(row, typeName)
      if (_.isUndefined(row)) {
        return;
      }
      let rows = [row];

      if (typeName === "StockQuery") {
        // 我的库存
        this.loadListByStock(rows);
      } else if (typeName === "StockVMIMini") {
        // 供应商库存
        this.loadStkVMI(rows);
      } else if (typeName === "HisOfferPriceByModel") {
        // 销售记录
        this.loadQuotedPriceLine(rows);
      }
    },
    // 我的库存出
    loadListByStock: async function (stkline, isChecklist) {
      // 根据传入的stock对象加载明细列表
      if (stkline.length > 0) {
        _.each(stkline, (stocks) => {
          let invQty = stocks.InvQty;
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty;
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            StkGUID: stocks.StkGUID,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Brand: stocks.Brand,
            Quality: stocks.Quality,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            Model: stocks.Model,
            SpModel: stocks.Model,
            StorageName: stocks.InvPosition,
            Qty: invQty,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            DeliveryDate: stocks.Leadtime,
            Price: this.ConvertNumber(stocks.SalesPrice),
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.SalesPrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.SalesPrice)
            ),
            FactDeliveryDate: null,
            ShareFee: stocks.shareFee,
            BuyPrice: stocks.BuyPrice,
            BuyerName: stocks.BuyerName,
            PBillNo: null,
            InDate: stocks.StkInTime,
            ProfitAmount: null,
            IsCancel: null,
            IsActive: 2,
            IsSystem: null,
            ProductCategory: stocks.ProductCategory,
          };
          if (this.isNullItemSource()) {
            this.SalesOrderAdd.LineList = [outlinobj];
          } else {
            this.SalesOrderAdd.LineList.push(outlinobj);
          }
        });
        this.$nextTick(function () {
          if (this.SalesOrderAdd.LineList.length > 0) {
            let tempSource = isChecklist ? this.itemSource : []; // 如果是库存选择后的结果则叠加
            let retList = tempSource;
            _.each(this.SalesOrderAdd.LineList, (i) => {
              retList.push(i);
            });
            this.SalesOrderAdd.LineList = retList;
            this.onBindData();
            this.CalcOrderAmoutAndGrossProfit(true);
          }
        });
      } else {
        this.$message({ message: "加载库存失败，请刷新后重试", type: "error" });
      }
    },
    // 加载供应商库存
    loadStkVMI: async function (rows) {
      if (rows.length > 0) {
        _.each(rows, (stocks) => {
          // if (!this.IsOutQty) {
          //   stocks.InvQty = ''
          // }
          let invQty = stocks.InvQty;
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty;
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            SOLineID: 0,
            SOLineGuid: null,
            StkGUID: stocks.StkGUID, // 库存GUID
            ProductCategory: stocks.ProductCategory,
            Model: stocks.Model,
            SpModel: stocks.Model,
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            StorageName: stocks.InvPosition,
            Qty: invQty,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            DeliveryDate: stocks.Leadtime,
            Price: this.ConvertNumber(stocks.SalesPrice),
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.SalesPrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.SalesPrice)
            ),
            FactDeliveryDate: null,
            ShareFee: stocks.shareFee,
            BuyPrice: stocks.BuyPrice,
            BuyerName: stocks.BuyerName,
            PBillNo: null,
            InDate: stocks.StkInTime,
            ProfitAmount: null,
            IsCancel: null,
            IsActive: 2,
            IsSystem: null,
          };
          if (this.isNullItemSource()) {
            this.SalesOrderAdd.LineList = [outlinobj];
          } else {
            this.SalesOrderAdd.LineList.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcOrderAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载供应商库存失败，请刷新后重试",
          type: "error",
        });
      }
    },
    isNullItemSource() {
      return (
        (this.itemSource.length === 0 || this.itemSource.length < 2) &&
        this.itemSource[0].Model === "" &&
        this.itemSource[0].Qty === null
      );
    },
    addExcelData(data) {
      let index = 0;
      if (this.itemSource && this.itemSource.length > 0) {
        index = this.itemSource.length - 1;
        let info = this.itemSource[index];
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop();
          index--;
        }
      }
      _.each(data, (item) => {
        this.getValue(item);
      });

      _.each(data, (item) => {
        index++;
        item.ID = index;
        this.itemSource.push(item);
      });
      this.isBulkAddShow = false;
      this.CalcOrderAmoutAndGrossProfit(true);
    },
    getValue(item) {
      // 如果单位为空 则默认参数配置的默认单位
      if (!this.hasValue(item.Unit)) {
        item.Unit = this.paramQtyUnit;
      }
      // 如果库存类型为空则默认参数配置的默认库存类型
      if (!this.hasValue(item.InvType)) {
        item.InvType = this.paramQtyType;
      }
      // 计算未税价和含税价和金额
      if (this.hasValue(item.Price)) {
        // 计算含税
        // console.log('计算含税', item.Price)
        let _priceIntax = this.CalcPriceInTax(item.Price);
        // item.PriceInTax = _priceIntax === 0 ? null : _priceIntax
      } else if (this.hasValue(item.PriceInTax)) {
        // 含税算未税
        // console.log('含税算未税')
        item.Price = this.CalcPrice(item.PriceInTax);
      }
      item.Amount = this.CalcAmout(item.Qty, item.PriceInTax).toFixed(2);
    },
    // 加载供应商库存
    LoadStkVMIGUID: async function (guid) {
      let stkVMIS = await this.$post(CONFIG.GetStkVMIByGUIDS, {
        VMGUIDS: guid,
      });
      this.$nextTick(function () {
        _.each(stkVMIS, (items) => {
          this.$set(items, "Qty", items.InvQty);
          this.$set(items, "SupplierInfo", items.SupplierName);
        });
        this.SalesOrderAdd.LineList = stkVMIS;
        this.CalcOrderAmoutAndGrossProfit(true);
        this.onBindData();
        this.isAllow = true; // 编辑禁用选择
        this.ResTaxRate();
      });
    },
    // 加载报价明细
    loadQuotedPriceLine(rows) {
      if (rows.length > 0) {
        _.each(rows, (stocks) => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            SalesQty: stocks.Qty,
            Price: this.ConvertNumber(stocks.QuotePrice),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.QuotePrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.QuotePrice)
            ),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
          };
          if (this.isNullItemSource()) {
            this.SalesOrderAdd.LineList = [outlinobj];
          } else {
            this.SalesOrderAdd.LineList.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcOrderAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载报价明细失败，请刷新后重试",
          type: "error",
        });
      }
    },

    companyChecked(item) {
      this.SalesOrderAdd.CustomerGUID = item.CCompanyID;
      this.SalesOrderAdd.CustomerName = item.CompanyName;
      this.getAddress(item);
      this.SalesOrderAdd.Contact = item.ContactName;
      this.SalesOrderAdd.Telephone = item.Telephone;
      // 默认跟进人，跟进人为空，默认当前登录用户 ,跟进人为多个取第一个。
      const regex = /,|，/; // 逗号
      if (this.hasValue(item.FollowUserName)) {
        let idList = item.FollowUserName.split(regex);
        let followUserName = "";
        if (idList.length > 1) {
          followUserName = idList[0];
        } else {
          followUserName = item.FollowUserName;
        }
        if (this.hasValue(followUserName)) {
          let info = _.find(this.selectOptionsAllUserURL, (item) => {
            return item.value === followUserName;
          });
          if (this.hasValue(info) && this.hasValue(info.key)) {
            this.SalesOrderAdd.SalerID = info.key;
            this.SalesOrderAdd.SalerName = info.value;
          }
        }
      }
      if (!this.hasValue(this.SalesOrderAdd.SalerID)) {
        this.SalesOrderAdd.SalerID = this.$store.state.accountInfo.UserID;
        this.SalesOrderAdd.SalerName = this.$store.state.accountInfo.UserName;
      }
      // 暂时取消
      // if (this.hasValue(item.TaxRate)) {
      //   this.SalesOrderAdd.TaxRate = item.TaxRate
      // } else {
      //   this.SalesOrderAdd.TaxRate = this.paramTax
      // }
      if (this.hasValue(item.CurrencyCode)) {
        this.SalesOrderAdd.CurrencyCode = item.CurrencyCode;
      }
      this.SalesOrderAdd.ReceiptType = item.PaymentType;
      this.CurrencyCodeChange();

      if (
        this.hasValue(item.TaxRate) &&
        this.SalesOrderAdd.TaxRate !== item.TaxRate
      ) {
        this.SalesOrderAdd.TaxRate = item.TaxRate;
        this.CalcTaxRate({ key: item.TaxRate });
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },
    onDeletedRow(d) {
      // 删除单行数据
      // console.log(d)
    },
    onDeletedCheckedRow(d) {
      // 删除多条数据
      //  console.log(d)
    },
    // 计算方法集合
    CalcPriceInTax(price) {
      // 计算含税价
      return this.toFloat(price * (1 + this.SalesOrderAdd.TaxRate / 100), 6);
    },
    CalcPrice(priceintax) {
      // 计算未税
      return this.toFloat(
        priceintax / (1 + this.SalesOrderAdd.TaxRate / 100),
        6
      );
    },
    CalcAmout(qty, salesPrice) {
      // 计算金额
      return this.toFloat((qty >= 0 ? qty : 0) * salesPrice, 2);
    },
    CalcPay(isEdit) {
      // 计算应付金额
      this.SalesOrderAdd.Amount = 0;
      _.each(
        isEdit ? this.SalesOrderAdd.LineList : this.itemSource,
        (o, index) => {
          this.SalesOrderAdd.Amount += this.toFloat(o.Amount, 2);
        }
      );
    },
    ConvertNumber(num) {
      // 转出数据格式
      return _.isNaN(num) || _.isUndefined(num) || num == null
        ? 0
        : this.toFloat(num);
    },
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      if (!this.hasValue(aftervalue)) {
        return;
      }
      // 修改的行
      let rowData = gridobj.row;

      /*************处理大小写配置start*************/
      //要处理大小写的列
      const handleColumns = ["Model", "Brand", "Packaging", "MakeYear"];
      if (
        !this.CompanySetup.EnableNotUpperCase &&
        handleColumns.includes(colname)
      ) {
        aftervalue = aftervalue.toUpperCase();
        rowData[colname] = aftervalue;
      }
      /*************处理大小写配置end***************/

      // debugger
      // 根据型号获取上次价格
      if (
        (colname === "Model" || colname === "CustomerPartNo") &&
        this.IsTakeOutPrint &&
        this.hasValue(this.SalesOrderAdd.CustomerName)
      ) {
        // 未税算含税
        // let data = _.extend(
        //   {},
        //   {
        //     Model: rowData.Model,
        //     CustomerName: this.SalesOrderAdd.CustomerName,
        //   }
        // );
        // if (this.isMatchByPackaging && rowData.Packaging) {
        //   data = _.extend(
        //     {},
        //     {
        //       Model: rowData.Model,
        //       CustomerName: this.SalesOrderAdd.CustomerName,
        //       Packaging: rowData.Packaging,
        //       CustomerPartNo: rowData.CustomerPartNo
        //     }
        //   );
        // }

        let data = {
          CustomerName: this.SalesOrderAdd.CustomerName,
        };
        if (colname == "Model") {
          data.Model = aftervalue;
        } else if (colname === "CustomerPartNo" && !this.hasValue(data.Model)) {
          data.CustomerPartNo = aftervalue;
        } else {
          return;
        }

        if (this.isMatchByPackaging) {
          data.Packaging = rowData.Packaging;
        }

        this.$post(CONFIG.GetPrice, data, (data) => {
          if (data !== undefined && data !== null) {
            let price = this.toFloat(data.Price, 6); // 未税价格
            let costPrice = this.toFloat(data.CostPrice, 6); // 成本价
            if (price > 0) {
              rowData["Price"] = price;
              let priceInTax = this.CalcPriceInTax(price);
              rowData["PriceInTax"] = priceInTax;
            }
            if (costPrice > 0) {
              rowData["CostPrice"] = costPrice;
            }

            // 客户输入的是客户料号，自动匹配上一次出库的型号、品牌、封装、年份、价格
            if (
              colname === "CustomerPartNo" &&
              !this.hasValue(rowData["Model"])
            ) {
              rowData["Model"] = data.Model || "";
              rowData["Brand"] = data.Brand || "";
              rowData["Packaging"] = data.Packaging || "";
              rowData["MakeYear"] = data.MakeYear || "";
            }
            if (
              colname === "Model" &&
              !this.hasValue(rowData["CustomerPartNo"])
            ) {
              rowData["CustomerPartNo"] = data.CustomerPartNo || "";
            }
          }
        });
      }
      if (colname === "Price") {
        // 未税算含税
        rowData["PriceInTax"] = this.CalcPriceInTax(aftervalue);
      }
      if (colname === "PriceInTax") {
        // 含税算未税
        rowData["Price"] = this.CalcPrice(aftervalue);
      }
      if (
        colname === "Price" ||
        colname === "PriceInTax" ||
        colname === "Qty" ||
        colname === "CostPrice"
      ) {
        let numbers = this.toFloat(rowData.Qty);
        let priceIntax = this.toFloat(rowData.PriceInTax); // 含税价格
        rowData["Amount"] = this.CalcAmout(numbers, priceIntax);
        this.CalcOrderAmoutAndGrossProfit(false);
      }
      //判断是否为最后一行，自动添加空行
      if (rowindex == gridobj.rows.length - 1) {
        this.$refs.flexGrid.addRow(true);
      }
    },

    // 加载bom配单数据
    LoadBomLine: async function (bomInfo) {
      let orders = await this.$post(CONFIG.bomLineGotoSales, bomInfo);
      this.BindDataToLineList(orders);
    },
    BindDataToLineList(orders) {
      _.each(orders.DetailList, (item) => {
        item.Price = item.NTQuotePrice;
        item.PriceInTax = item.QuotePrice;
        item.BuyPrice = item.BuyPrice;
        if (item.NeedDeliveryDate === undefined) {
          item.NeedDeliveryDate = "";
        }
        item.Amount = this.CalcAmout(
          this.ConvertNumber(item.Qty),
          this.ConvertNumber(item.PriceInTax)
        );
      });
      this.SalesOrderAdd = _.extend({}, this.SalesOrderAdd, orders);
      this.SalesOrderAdd.LineList = orders.DetailList;
      this.itemSource = this.SalesOrderAdd.LineList;

      this.getAddress(this.SalesOrderAdd);
      this.isPopup = true;
      this.$nextTick(function () {
        this.CalcOrderAmoutAndGrossProfit(true);
        this.SalesOrderAdd.IsAdd = true;
        this.ResTaxRate();
      });
      this.isAllow = false; // 编辑禁用选择
    },
    ResTaxRate() {
      if (!this.hasValue(this.SalesOrderAdd.TaxRate)) {
        this.SalesOrderAdd.TaxRate = this.paramTax;
        this.CalcTaxRate();
      }
    },
    // 根据传入BOMGUIDS那整张需求单生成销售单
    LoadListByBOMGUIDS: async function (mltGuids) {
      let orders = await this.$post(CONFIG.SearchByBOMGUIDS, {
        BOMGUID: mltGuids,
      });
      this.SalesOrderAdd = _.extend({}, this.SalesOrderAdd, orders);
      this.getAddress(this.SalesOrderAdd);
      this.isPopup = true;
      this.$nextTick(function () {
        this.onBindData();
        this.CalcOrderAmoutAndGrossProfit(true);
        this.SalesOrderAdd.IsAdd = true;
      });
      this.isAllow = false; // 编辑禁用选择
    },
    onBillIconClick() {
      if (this.SalesOrderAdd.IsAdd) {
        this.IsManual = !this.IsManual;
        if (!this.IsManual) {
          this.SalesOrderAdd.BillNo = "";
        }
      } else {
        this.$message({
          message: "单据编号生成后不可以修改！",
          type: "warning",
        });
      }
    },
    autocompleteChange(val) {
      this.startTime = new Date();
      _.delay(() => {
        this.endTime = new Date();
        if (this.endTime - this.startTime >= 800) {
          this.autocompleteSearch(true, val);
        }
      }, 800);
    },
    autocompleteSearch(isSearch, data) {
      if (!isSearch) return;
      this.autocompleteSearchData = data;
      if (data.length > 0) {
        // this.autocompleteShow = true
        // this.$refs.autocompleteSelect.$el.style.left = getabsleft(this.$refs.znlAutocomplete.$el) + 65 + 'px'
        // this.$refs.autocompleteSelect.$el.style.top = getabstop(this.$refs.znlAutocomplete.$el) + 30 + 'px'
      } else {
        this.autocompleteShow = false;
      }
    },
    selectionChanged(val) {
      this.autocompleteShow = false;
      this.companyChecked([val]);
    },
    confirmPrint(data) {
      this.onPrint();
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
    },
    // 连续录单模式保存到参数配置
    onContinuousRecordValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isContinueInputMode ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("salesOrder");
          }
        );
      }
    },
    // 自动备货保存到参数配置
    onAotuReserveValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsAotuReserve") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isAotuReserve ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("salesOrder");
          }
        );
      }
    },

    getAddress(item) {
      this.selectOptionsAddress = [];
      // 获取地址信息组合下拉框数据
      if (this.hasValue(item.DeliveryAddress)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.DeliveryAddress,
          value: item.DeliveryAddress,
        });
      }
      if (this.hasValue(item.Address)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address,
          value: item.Address,
        });
      }
      if (this.hasValue(item.Address2)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address2,
          value: item.Address2,
        });
      }
      if (this.hasValue(item.Address3)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address3,
          value: item.Address3,
        });
      }
      // 获取地址信息 如果存在货运地址默认货运地址
      if (this.hasValue(item.DeliveryAddress)) {
        this.SalesOrderAdd.DeliveryAddress = item.DeliveryAddress;
      }
      // 获取地址信息 如果没有货运地址默认公司地址
      if (!this.hasValue(item.DeliveryAddress) && this.hasValue(item.Address)) {
        this.SalesOrderAdd.DeliveryAddress = item.Address;
      }
      // 获取地址信息 如果公司地址地址默认Address1
      if (!this.hasValue(item.Address) && this.hasValue(item.Address2)) {
        this.SalesOrderAdd.DeliveryAddress = item.Address2;
      }
      // 获取地址信息 如果没有Address1默认Address2
      if (!this.hasValue(item.Address2) && this.hasValue(item.Address3)) {
        this.SalesOrderAdd.DeliveryAddress = item.Address3;
      }
      // 如果没有地址信息，清空上次输入的地址信息
      if (
        !this.hasValue(item.Address) &&
        !this.hasValue(item.Address2) &&
        !this.hasValue(item.Address3) &&
        !this.hasValue(item.DeliveryAddress)
      ) {
        this.$refs.DeliveryAddress.clear();
      }
    },
    // 自动打印保存到参数配置
    onPrintValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsPrint ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("salesOrder");
          }
        );
      }
    },
    changeAddressInputHandler(value) {
      const selectValue = value.target.value;
      let addres = _.find(this.selectOptionsAddress, function (item) {
        return item.key === selectValue;
      });
      if (!this.hasValue(addres) && _.trim(selectValue) !== "") {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: selectValue,
          value: selectValue,
        });
      }
      this.SalesOrderAdd.DeliveryAddress = selectValue;
    },
  },
  async created() {
    var a = [
      { ItemValue: "1", ItemName: "我方支付" },
      { ItemValue: "2", ItemName: "我方垫付" },
      { ItemValue: "3", ItemName: "客户支付" },
      { ItemValue: "4", ItemName: "供应商支付" },
    ];
    this.FreightClearFormOptions = _.map(a, (item) => {
      return { key: item.ItemValue, value: item.ItemName };
    });

    // 开票类型
    // var b = [{ ItemValue: 1, ItemName: '普通发票' }, { ItemValue: 2, ItemName: '增值税票' }]
    // this.InvoiceTypeOptions = _.map(b, item => { return { key: item.ItemValue, value: item.ItemName } })
  },
  mounted() {
    this.$nextTick(() => {
      if (this.editType === "addNew" && this.autoAddNewRow) {
        this.$refs.flexGrid.addRow(true);
      }
    });
  },
};
</script>

<style lang="scss" >
.dialog-salesorderedit-box {
  .sales-grid-min-box {
    background: #faa !important;
  }

  .el-loading-mask {
    background: transparent !important;
  }
  .znl-grid-component {
    border-bottom: 0 !important;
    background: transparent !important;
  }
  .znlBottom {
    height: 0 !important;
  }

  .el-pagination button,
  .footer .el-pager li {
    background: transparent;
  }
}
.edit2-scoped {
  .znl-dialog__footer {
    margin-bottom: 12px;
  }
}

// .dialog-salesorderedit-box {
//   .el-table {
//     max-height: 330px;
//   }
// }
</style>

